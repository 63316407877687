<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div
    class="xl:max-w-[50%] md:max-w-[50%] flex flex-col justify-end items-stretch md:justify-center z-0 pointer-events-none"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4 pointer-events-auto"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic force-display />

      <div class="">
        {{ t("onboarding.step11.strategy") }}
      </div>

      <div class="p-2">
        <PaginatedOnboardingFooter hide-ok-button />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
