<script setup lang="ts">
import { Scarcity } from "fungi-types";
import { useI18n } from "vue-i18n";
import { getCardLevel } from "fungi-utils/cardXp";
import { computed } from "vue";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    xp?: number;
    season: number;
    scarcity: Scarcity;
  }>(),
  {
    xp: 0,
  }
);

const cardLevel = computed(() => {
  return getCardLevel(props.xp);
});

const scarcityBonus = computed(() => {
  switch (props.scarcity) {
    case Scarcity.SILVER:
      return 5;
    case Scarcity.GOLD:
      return 10;
    case Scarcity.PLATINUM:
      return 15;
    default:
      return 0;
  }
});

const seasonBonus = computed(() => {
  const season = Number(props.season);
  switch (season) {
    case 2025:
      return 5;
    case 2024:
      return 2;
    default:
      return 0;
  }
});

const totlaBonus = computed(() => {
  return cardLevel.value.bonus + scarcityBonus.value + seasonBonus.value;
});
</script>

<template>
  <Tooltip>
    <div
      class="bg-neutral-950 rounded-md pr-2 pl-1.5 text-neutral-300 capitalize cursor-pointer text-xs h-full items-center flex"
    >
      <span class="">+{{ totlaBonus }}</span
      >%
    </div>
    <template #content>
      <div class="w-32 flex flex-col gap-1">
        <div v-if="seasonBonus" class="flex flew-row justify-between w-full items-center">
          <div class="text-neutral-850 capitalize">{{ season }}</div>
          <div class="bg-green-60 p-1 py-0.5 rounded-md text-neutral-850">+{{ seasonBonus }} %</div>
        </div>
        <div class="flex flew-row justify-between w-full">
          <div class="text-neutral-850 capitalize">{{ t(`scarcities.${scarcity}`) }}</div>
          <div class="bg-green-60 p-1 py-0.5 rounded-md text-neutral-850">
            +{{ scarcityBonus }} %
          </div>
        </div>
        <div v-if="cardLevel.currentLevel > 0" class="flex flew-row justify-between w-full">
          <div class="text-neutral-850 capitalize">Niveau {{ cardLevel.currentLevel }}</div>
          <div class="bg-green-60 p-1 py-0.5 rounded-md text-neutral-850">
            +{{ cardLevel.bonus }} %
          </div>
        </div>
      </div>
    </template>
  </Tooltip>
</template>
