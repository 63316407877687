import {
  format,
  startOfWeek,
  subWeeks,
  endOfWeek,
  isBefore,
  set,
  differenceInYears,
} from "date-fns";
import { IDateRange } from "~/common/interfaces/ITradingDashboard";
import { enUS, fr } from "date-fns/locale";

export const getlastWeeksDateRange = (
  numberOfWeeks: number,
  since: Date = new Date("2024-02-25 12:00:00")
) => {
  const today = new Date();
  const lastWeeksDateRanges: IDateRange[] = [];

  for (let i = 0; i < numberOfWeeks; i++) {
    const startDate = startOfWeek(subWeeks(today, i), { weekStartsOn: 1 });
    if (isBefore(startDate, since)) break;
    const endDate = endOfWeek(subWeeks(today, i), { weekStartsOn: 1 });
    lastWeeksDateRanges.push({
      from: startDate,
      to: endDate,
    });
  }

  return lastWeeksDateRanges;
};

export const getWeekDateRange = (date: Date = new Date()) => {
  const startDate = startOfWeek(date, { weekStartsOn: 1 });
  const endDate = endOfWeek(date, { weekStartsOn: 1 });

  return { from: startDate, to: endDate };
};

export const formatDate = (
  date: string | Date,
  locale: string = "enUS",
  dateFormat: string = "dd MMMM"
) => {
  let res = enUS;
  switch (locale) {
    case "fr":
      res = fr;
      break;
    case "en":
    default:
      res = enUS;
      break;
  }
  return `${format(date, dateFormat, { locale: res })}`;
};

export const calculateAge = (birthDate: Date) => {
  const today = new Date();
  const birthdateObj = new Date(birthDate);
  return differenceInYears(today, birthdateObj);
};
