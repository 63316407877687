<script setup lang="ts">
import { RouterView } from "vue-router";
import PanelController from "@/components/panel/PanelController.vue";
import { NotifType, useStoreApp } from "~/stores/storeApp";
import { useHead } from "@vueuse/head";
import GlobalModals from "~/components/GlobalModals.vue";
import { usePrimaryMarketplace } from "~/stores/storePrimaryMarketplace";
import { onMounted, onUnmounted } from "vue";
import { initDataLayer } from "~/utils/gtm";

const storeApp = useStoreApp();
useHead({
  title: "Fungiball",
  meta: [
    { name: "description", content: "Fungiball Tennis Fantasy Game" },
    {
      name: "theme-color",
      content: "#000000",
    },
  ],
  link: [
    {
      rel: "icon",
      type: "image/svg+xml",
      href: "/favicon.svg",
    },
  ],
});

//keyboard handler
function keyHandler(e: KeyboardEvent) {
  if (e.key === "Escape") {
    storeApp.showBidModal = false;
  }
  if (e.altKey && e.key === "ArrowUp") {
    storeApp.debug = !storeApp.debug;
  }
  if (e.altKey && e.key === "w") {
    useStoreApp().displayNotif(NotifType.WIN, usePrimaryMarketplace().auctions[2], "Bob");
  }
  if (e.altKey && e.key === "o") {
    useStoreApp().displayNotif(NotifType.OUTBID, usePrimaryMarketplace().auctions[0], "Bob");
  }
  if (e.altKey && e.key === "v") {
    useStoreApp().displayNotif(NotifType.OVERBID, usePrimaryMarketplace().auctions[0], "Bob");
  }
  if (e.altKey && e.key === "t") {
    useStoreApp().displayNotif(NotifType.TOPBID, usePrimaryMarketplace().auctions[0], "Bob");
  }
}

onMounted(async () => {
  window.removeEventListener("keydown", keyHandler);
});

onUnmounted(() => {
  window.removeEventListener("keydown", keyHandler);
});

// Google Tag Manager
initDataLayer();
</script>

<template>
  <div class="flex flex-col h-full min-h-screen overflow-hidden" @keyup="keyHandler">
    <router-view />
    <PanelController />
    <GlobalModals />
    <Notif />
  </div>
</template>

<style scoped></style>
