<script setup lang="ts">
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import { storeToRefs } from "pinia";

const emit = defineEmits(["onClose"]);

const onboardingStepsStore = useOnboarding();
const { currentStep, showOnboarding, showOverlay } = storeToRefs(onboardingStepsStore);
</script>

<template>
  <teleport to="body">
    <transition mode="out-in">
      <div
        v-if="showOnboarding"
        id="onboarding-modal"
        class="fixed z-[60] overflow-y-auto flex justify-center inset-0 p-2"
        :class="{
          [currentStep?.containerClasses ?? '']: true,
          'pointer-events-none allow-scroll': !!currentStep?.noOverlay,
        }"
        :data-step="onboardingStepsStore.step"
      >
        <div
          v-if="!currentStep?.noOverlay"
          :class="`fixed inset-0 transition-all duration-300 ${showOverlay ? 'bg-onboarding-overlay backdrop-blur-sm' : 'bg-transparent'}`"
        ></div>

        <img
          alt="Onboarding coach"
          v-show="currentStep.coachPosition !== 'hidden'"
          src="/images/coach/coach.png"
          :class="`${currentStep.coachSize === 'small' ? 'h-1/2' : 'h-2/3'} fixed bottom-0 ${currentStep.coachPosition === 'left' ? 'left-0' : 'right-0'} ${currentStep.coachPosition === 'left' ? 'transform scale-x-[-1]' : ''} hidden lg:block`"
        />

        <transition mode="out-in">
          <component :is="currentStep.component"></component>
        </transition>
      </div>
    </transition>
  </teleport>
</template>

<style scoped>
/** Prevent scrolling behind overlay */
:global(#app:has(~ #onboarding-modal:not(.allow-scroll):not([data-step="9"]))) {
  overflow: hidden;
  position: fixed;
}

.v-enter-active {
  @apply transition ease-out duration-100 transform;
}
.v-leave-active {
  @apply ease-in duration-75;
}

.v-enter-from,
.v-leave-to {
  @apply opacity-0;
}

.v-enter-to,
.v-leave-from {
  @apply opacity-100;
}

@media (max-height: 900px) {
  img {
    height: 33%;
  }
}
</style>
