<script setup lang="ts">
import { useI18n } from "vue-i18n";
import Trans from "~/modules/translations";
const { locale, t } = useI18n();

import HeaderSkeleton from "./HeaderSkeleton.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";

const route = useRoute();

const contents = [
  {
    background: "/images/headers/locker.jpg",
    title: t("pages.myDeck.headers.myCards.title"),
    subtitle: t("pages.myDeck.headers.myCards.description"),
  },
  {
    background: "/images/headers/locker.jpg",
    title: t("pages.myDeck.headers.myCardsForSale.title"),
    subtitle: t("pages.myDeck.headers.myCardsForSale.description"),
  },
  {
    background: "/images/headers/locker.jpg",
    title: t("pages.myDeck.headers.transactions.title"),
    subtitle: t("pages.myDeck.headers.transactions.description"),
  },
  {
    background: "/images/headers/locker-equipment.jpg",
    title: t("pages.myDeck.headers.myEquipments.title"),
    subtitle: t("pages.myDeck.headers.myEquipments.description"),
  },
];

const content = computed(() => {
  switch (route.name) {
    case "MyCards":
      return contents[0];
    case "Listed":
      return contents[1];
    case "Transactions":
      return contents[2];
    case "MyEquipments":
      return contents[3];
    default:
      return contents[0];
  }
});
</script>

<template>
  <HeaderSkeleton
    :title="content.title"
    :subtitle="content.subtitle"
    :background="content.background"
    :show-header="true"
  >
    <router-link
      :to="Trans.i18nRoute({ name: 'MyCards', params: $route.params })"
      class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0"
      >{{ $t("pages.myDeck.myCards") }}</router-link
    >
    <router-link
      :to="Trans.i18nRoute({ name: 'MyEquipments', params: $route.params })"
      class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0"
      >{{ $t("pages.myDeck.myEquipments") }}</router-link
    >
    <router-link
      :to="Trans.i18nRoute({ name: 'Transactions' })"
      class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0"
      >{{ $t("pages.myDeck.transactions") }}</router-link
    >
  </HeaderSkeleton>
</template>

<style scoped>
.router-link-exact-active {
  @apply !border-slate-600 !bg-black transition-colors;
}
</style>
