<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useDynamicSort from "~/composables/useDynamicSort";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreApp } from "~/stores/storeApp";
import {
  CurrentlyAvailableCardAuction,
  useCardDetails,
} from "~/components/card-details/use-card-details";
import DetailsSection from "~/components/card-details/DetailsSection.vue";
import AvailableCardsTable from "~/components/card-details/AvailableCards/AvailableCardsTable.vue";
import AvailableCardCard from "~/components/card-details/AvailableCards/AvailableCardCard.vue";
import { MarketType } from "fungi-types";
const { t } = useI18n();

const cardDetails = useCardDetails();
const { cardSales } = storeToRefs(cardDetails);

const availableCards = useDynamicSort(cardSales);

function openBidModalForToken(token: CurrentlyAvailableCardAuction) {
  useStoreMarketplace().selectedItem = token;

  if (token.marketType === MarketType.PRIMARY) {
    useStoreApp().setBidModalState(true);
  } else {
    useStoreApp().showBuyModal = true;
  }
}
</script>

<template>
  <DetailsSection :name="t('cardDetails.availableCards.title')">
    <AvailableCardsTable @bid-or-buy="openBidModalForToken" class="hidden lg:table" />
    <ol class="flex lg:hidden flex-col items-stretch gap-4">
      <li v-for="card in availableCards.sorted.value" :key="card.cuid">
        <AvailableCardCard
          :card="card"
          :skill="cardDetails.skill!"
          @bid-or-buy="openBidModalForToken"
        />
      </li>
    </ol>
  </DetailsSection>
</template>
