import { formatEther } from "@ethersproject/units";
import type { BigNumberish } from "ethers";

export const truncateAddress = (address: string | null) => {
  if (!address) return "No Account";
  return `${address.slice(0, 5)}...${address.slice(-4)}`;
};

export const toHex = (num: string | number) => {
  const val = Number(num);
  return `0x${val.toString(16)}`;
};

export const toEther = (num: BigNumberish) => {
  return parseFloat(formatEther(num));
};
