import { BigNumber } from "ethers";
import useConvert from "~/composables/useConvert";

export type PreviousOwner = {
  owner: string;
  wallet: string;
  acquiredAt: Date;
  transaction: string;
  price: number;
};

export type OwnersHistory = PreviousOwner[];

export type OwnersHistoryDto = (Omit<PreviousOwner, "price" | "acquiredAt"> & {
  price: string;
  acquired_at: string;
})[];

const { weiToMatic } = useConvert;

export function mapOwnersHistoryDtoToOwnersHistory(dto: OwnersHistoryDto): OwnersHistory {
  return dto.map((record) => ({
    owner: record.owner,
    wallet: record.wallet,
    transaction: record.transaction,
    acquiredAt: new Date(record.acquired_at),
    price: weiToMatic(BigNumber.from(record.price)),
  }));
}
