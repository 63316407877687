<script setup lang="ts">
import { ref, computed, FunctionalComponent } from "vue";
import { vOnClickOutside } from "@vueuse/components";

const emit = defineEmits(["onChange"]);

const isOpen = ref(false);

const props = withDefaults(
  defineProps<{
    selectedId: string | number | null;
    options: { label: string; value: any; id: string | number; label2?: string }[];
    prefix?: FunctionalComponent;
    size?: "sm" | "md";
    position?: "top" | "bottom" | "top-left" | "bottom-left" | "top-right" | "bottom-right";
  }>(),
  {
    size: "md",
    position: "bottom",
  }
);

const setDrodpown = (state: boolean) => {
  isOpen.value = state;
};

const onClickOutside = () => {
  if (isOpen.value) isOpen.value = false;
};

const handleOnSelected = (index: number) => {
  emit("onChange", props.options[index]);
};

const selectedItem = computed(() => {
  return props.options.find((option) => option.id === props.selectedId);
});

const position = computed(() => {
  const top = props.size === "sm" ? "bottom-9" : "bottom-12";
  switch (props.position) {
    case "top":
      return `${top} min-w-full`;
    case "bottom":
      return "min-w-full";
    case "top-left":
      return `${top} w-fit`;
    case "bottom-left":
      return "w-fit";
    case "top-right":
      return `${top} w-fit right-0`;
    case "bottom-right":
      return `w-fit right-0`;
    default:
      return "w-full";
  }
});
</script>

<template>
  <div class="self-center w-full relative" @click="setDrodpown(!isOpen)">
    <div class="flex flex-row">
      <div
        :class="`input-select rounded-lg text-xs w-full flex flex-row ${size === 'sm' ? 'py-2 px-2' : 'px-3 h-11'}`"
      >
        <div v-if="prefix" class="pr-2 border-r-[1px] border-slate-600 mr-2 p-0">
          <component
            :is="prefix"
            stroke-width="1"
            :size="size === 'sm' ? 16 : null"
            class="text-slate-200"
          />
        </div>
        <div class="flex items-center justify-between w-full">
          <div class="flex">
            <div class="text-md">
              {{ selectedItem?.label }}
              <span class="text-slate-400" v-if="selectedItem?.label2">{{
                selectedItem.label2
              }}</span>
            </div>
          </div>
          <icon-fg-chevron class="ml-3" />
        </div>
      </div>
    </div>

    <div
      v-on-click-outside="onClickOutside"
      id="dropdown"
      :class="`z-10 mt-1 ${isOpen ? '' : 'hidden'} ${position} bg-gray-700 divide-y divide-gray-100 rounded-lg shadow absolute`"
    >
      <ul
        class="py-2 text-sm text-gray-200 whitespace-nowrap max-h-40 overflow-auto"
        aria-labelledby="dropdownDefaultButton"
      >
        <li
          v-for="(option, index) in options"
          :key="option.id"
          @click.prevent="handleOnSelected(index)"
        >
          <a href="#" class="block px-4 py-2 hover:hover:bg-gray-600 hover:text-white">
            {{ option.label }}
            <span class="text-slate-400" v-if="option?.label2">{{ option.label2 }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.input-select {
  @apply text-white text-center items-center
  transition-colors duration-300  ease-in-out
  bg-grey-60/10
  border border-gray-50/10 hover:border-gray-50/10
  hover:bg-grey-60/20
  disabled:text-grey-60
  disabled:hover:bg-grey-60/5
  disabled:bg-grey-60/5;
  text-transform: none;
  cursor: pointer;
}
</style>
