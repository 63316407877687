<script setup lang="ts">
type TOption<T> = {
  label: string;
  value: T;
};

const props = defineProps<{
  value: unknown;
  options: TOption[];
  activeClass?: string;
}>();

const emit = defineEmits<{
  (event: "change", value: unknown): unknown;
}>();

function onChange(value: unknown) {
  emit("change", value);
}

function isActive(optionValue: unknown) {
  return optionValue === props.value || JSON.stringify(optionValue) === JSON.stringify(props.value);
}
</script>

<template>
  <ul
    class="bg-white/10 self-stretch lg:self-auto lg:bg-transparent lg:bg-opacity-100 flex flex-row items-center justify-around lg:justify-start gap-2 py-2 px-1 lg:p-0 rounded-lg lg:rounded-none"
  >
    <li
      v-for="option in props.options"
      :key="option.value"
      @click="onChange(option.value)"
      :class="`px-2 py-1 text-sm rounded-lg font-semibold text-white  cursor-pointer ${isActive(option.value) ? `${props.activeClass} hover:${props.activeClass} cursor-pointer` : 'hover:bg-white/10'}`"
    >
      {{ option.label }}
    </li>
  </ul>
</template>

<style scoped></style>
