<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import type { ICard } from "~/application/types/Card";
import { BigNumber, ethers } from "ethers";
import useConvert from "~/composables/useConvert";
import { computed, ref } from "vue";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { displayError } from "~/utils/errors";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { useStoreApp } from "~/stores/storeApp";
import { useRouter } from "vue-router";
import usePanel from "~/composables/usePanel";
import Trans from "~/modules/translations";

const props = defineProps<{
  item: ICard;
  amount: BigNumber;
}>();
const { t } = useI18n();
const { weiToMatic, weiToUsd, formatUsd } = useConvert;
const decimals = ref(3);
const bidAmount = ref(props.amount.add(props.amount.mul(10).div(100)));

const storeMarketPlace = useStoreMarketplace();
const router = useRouter();
const storeApp = useStoreApp();
const price = computed(() => {
  return { usd: weiToUsd(props.amount), wei: props.amount, matic: weiToMatic(props.amount) };
});

/**
 * Computes the minimum price for bidding on a card, which is 110% of the card's current price.
 * The price is rounded down to the nearest number that has only three significant digits.
 *
 * @returns {Object} An object containing the minimum bid price in various formats:
 * - usd: The price in USD, converted from wei using the current MATIC-USD exchange rate.
 * - wei: The price in wei, which is the smallest unit of MATIC.
 * - matic: The price in MATIC, converted from wei.
 */
const minPrice = computed(() => {
  let factor = ethers.BigNumber.from("10").pow(18 - decimals.value);
  const tenPercent = props.amount.mul(10).div(100);
  const _minPrice = props.amount.add(tenPercent).div(factor).mul(factor);
  return { usd: weiToUsd(_minPrice), wei: _minPrice, matic: weiToMatic(_minPrice) };
});
const amountTooLow = computed(() => bidAmount.value.lt(minPrice.value.wei));
function updateBidAmount(e: BigNumber) {
  bidAmount.value = e;
}

const loginComplete = ref(true);

async function bid() {
  try {
    await storeMarketPlace.bidOnCard(props.item.tokenId.toString(), bidAmount.value);
  } catch (error) {
    displayError(error);
  }
  usePanel.closeModal();
}

function loginToBid() {
  usePanel.closeModal();
  router.push(Trans.i18nRoute({ name: "Connect" }));
}
</script>

<template>
  <div class="w-full flex flex-col gap-6 justify-center text-center relative">
    <card-item :card="item" />
    <div class="w-full flex justify-between items-center">
      <span class="uppercase font-display text-greyMed">{{ $t("panel.bidPanel.highestBid") }}</span>
      <div class="flex items-center gap-2">
        <div class="flex items-center">
          <span class="text-lg md:text-xl">{{ price.matic }}</span>
          <icon-ovc-wmatic class="inline-icon ml-1" />
        </div>
        <span class="text-base md:text-lg text-greyLight"> ≈ {{ formatUsd(+price.usd) }}</span>
      </div>
    </div>
    <div class="w-full grid grid-flow-col">
      <span class="uppercase font-display text-greyMed text-left">{{
        $t("panel.bidPanel.enterBid")
      }}</span>
      <div class="relative">
        <ConverterInput
          :value="minPrice.wei"
          @wei-amount="updateBidAmount"
          :hideConversion="false"
          :decimals="decimals"
        >
          <template #msg v-if="amountTooLow">
            <span class="text-error">Amount too low</span>
          </template>
        </ConverterInput>
      </div>
    </div>
    <button
      @click="bid"
      class="btn-primary h-12 mt-2"
      :disabled="storeMarketPlace.bidInProgress || !loginComplete || amountTooLow"
    >
      <icon-fgc-loading v-if="storeMarketPlace.bidInProgress" />
      <span v-else>{{ $t("button.bid") }}</span>
    </button>
  </div>
  <div
    v-if="!useStoreUserV2().isWalletConnected"
    class="absolute inset-0 flex justify-center items-center backdrop-blur-sm bg-black/30"
  >
    <button @click="loginToBid" class="w-[90%] btn-primary text-black">
      {{ $t("button.connect-wallet") }}
    </button>
  </div>
  <div
    v-else-if="!loginComplete"
    class="absolute inset-0 flex justify-center items-center backdrop-blur-sm bg-black/30"
  >
    <button @click="loginToBid" class="w-[90%] btn-secondary bg-red-600 text-black">
      <icon-clarity:warning-line class="inline-icon" />{{ $t("button.completeregistration") }}
    </button>
  </div>
</template>

<style scoped></style>
