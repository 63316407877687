<script setup lang="ts">
import { onMounted, onUnmounted, ref, toRefs } from "vue";
import { NftSkill as TNftSkill } from "fungi-types";
import NftSkill from "~/components/marketplace/NftSkill.vue";

const props = defineProps<{
  cards: TNftSkill[];
  timing: number;
  hideScore?: boolean;
}>();
const { cards } = toRefs(props);

const activeCardIdx = ref(0);
const cardUpdateInterval = ref();

onMounted(() => {
  cardUpdateInterval.value = setInterval(() => {
    activeCardIdx.value = (activeCardIdx.value + 1) % cards.value.length;
  }, props.timing);
});
onUnmounted(() => clearInterval(cardUpdateInterval.value));
</script>

<template>
  <div class="relative md:w-full flex items-center justify-center">
    <NftSkill :nft="cards[0]" class="opacity-0">
      <template #footer-data>
        <div class="w-full flex items-center justify-between">
          <div class="rounded-br-lg px-3 small-text md:text-sm score-container">
            <p>{{ cards[0].score }}</p>
          </div>
        </div>
      </template>
    </NftSkill>

    <template v-for="(card, idx) in cards" :key="idx">
      <transition mode="out-in">
        <div class="absolute" v-show="idx === activeCardIdx">
          <NftSkill :nft="card">
            <template #footer-data>
              <div v-if="!props.hideScore" class="w-full flex items-center justify-between">
                <div
                  :class="`bg-skill-${card.skill}`"
                  class="rounded-br-lg px-3 small-text md:text-sm score-container"
                >
                  <p class="font-sans font-semibold">{{ card.score }}</p>
                </div>
              </div>
            </template>
          </NftSkill>
        </div>
      </transition>
    </template>
  </div>
</template>

<style scoped>
.v-enter-active {
  @apply transition ease-linear transform duration-75;
}

.v-leave-active {
  @apply transition ease-linear transform duration-1000;
}

.v-enter-from,
.v-leave-to {
  @apply opacity-0;
}

.v-enter-to,
.v-leave-from {
  @apply opacity-100;
}
</style>
