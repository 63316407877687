<script setup lang="ts">
import { computed, onMounted, ref, toRefs, watch } from "vue";
import Chart, { ScriptableContext } from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { enUS, fr } from "date-fns/locale";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  data: { value: number; date: Date }[];
  xTicks: number;
  labels?: Date[];
  yTicks?: number;
  min?: number;
  max?: number;
  lineColor?: string;
  gradientColor0?: string;
  gradientColor1?: string;
}>();

const { data, labels, gradientColor0, gradientColor1, lineColor } = toRefs(props);

const { locale } = useI18n();
const dateLocale = computed(() => (locale.value === "fr" ? fr : enUS));

let chart: Chart;
let legendChart: Chart;
const legendCanvas = ref<HTMLCanvasElement>();
const chartCanvas = ref<HTMLCanvasElement>();

const width = ref<number>();
const height = ref<number>();
const chartGradient = ref<CanvasGradient>();

const colWidthPx = 20;

const needsGradientRefresh = ref(false);

function determineXTicks(startDate: Date, endDate: Date, numberOfTicks: number): Date[] {
  const timeDiff = endDate.getTime() - startDate.getTime();
  const timePerTick = timeDiff / numberOfTicks;

  const ticks: Date[] = [];

  for (let i = 0; i <= numberOfTicks; i++) {
    ticks.push(new Date(startDate.getTime() + i * timePerTick));
  }

  return ticks;
}

function groupPointsByTicks(
  inputData: { value: number; date: Date }[],
  ticks: Date[]
): { value: number | null; tick: Date }[] {
  const outputData: { value: number; tick: Date; count: number }[] = Array.from(
    { length: ticks.length },
    (_, i) => ({ value: 0, tick: ticks[i], count: 0 })
  );

  // Iterate over the input data and group it by the most recent tick for each data point
  for (const dataPoint of inputData) {
    const nextTickIdx = ticks.findIndex((tick) => tick.getTime() > dataPoint.date.getTime());

    const idx = nextTickIdx === -1 ? -1 : nextTickIdx - 1;

    outputData.at(idx)!.value += dataPoint.value;
    outputData.at(idx)!.count++;
  }

  return outputData.map(({ value, count, tick }) => ({
    value: count === 0 ? null : value / count,
    tick: tick,
  }));
}

const timeTicks = computed(() => {
  if (data.value.length === 0) return [];

  return determineXTicks(data.value.at(0)!.date, data.value.at(-1)!.date, props.xTicks);
});
const groupedData = computed(() => {
  return groupPointsByTicks(props.data, timeTicks.value).map((_) => _.value);
});

watch([gradientColor0, gradientColor1], () => {
  needsGradientRefresh.value = true;
});

watch([lineColor], (newLineColor) => {
  if (!chart) return;

  chart.data.datasets.at(0)!.borderColor = newLineColor;
});

function getGradient(
  ctx: CanvasRenderingContext2D,
  chartArea: { left: number; right: number; top: number; bottom: number }
) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  if (
    needsGradientRefresh.value ||
    !chartGradient.value ||
    width.value !== chartWidth ||
    height.value !== chartHeight
  ) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width.value = chartWidth;

    height.value = chartHeight;
    chartGradient.value = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    chartGradient.value!.addColorStop(0, gradientColor0.value ?? "#33AAFF00");
    chartGradient.value!.addColorStop(1, gradientColor1.value ?? "#33AAFFCC");

    needsGradientRefresh.value = false;
  }

  return chartGradient.value;
}

const tickSize = computed(() => {
  if ((props.yTicks ?? 0) <= 0) return undefined;

  if (props.min != null && props.max != null && props.min < props.max) {
    return (props.max - props.min) / props.yTicks!;
  }

  if (props.data.length === 0) return 0;

  const dataValues = props.data.map(({ value }) => value);
  const min = Math.min(...dataValues);
  const max = Math.max(...dataValues);

  return Math.round((max - min) / props.yTicks!);
});

onMounted(() => {
  chart = new Chart(chartCanvas.value!, {
    type: "line",
    data: {
      datasets: [
        {
          label: "Data",
          data: groupedData.value,
          fill: true,
          borderColor: props.lineColor,
          cubicInterpolationMode: "monotone",
          backgroundColor: (context: ScriptableContext<any>) => {
            const { ctx, chartArea } = context.chart;

            if (!chartArea) return;

            return getGradient(ctx, chartArea);
          },
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      spanGaps: true,
      scales: {
        y: {
          min: props.min ?? undefined,
          max: props.max ?? undefined,
          ticks: {
            display: false,
            stepSize: tickSize.value,
          },
          grid: {
            drawTicks: true,
            color: "#E8EAED33",
          },
        },
        x: {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: dateLocale.value === enUS ? "MM/dd" : "dd/MM",
            },
          },
          adapters: {
            date: {
              locale: dateLocale.value,
            },
          },
          grid: {
            drawTicks: false,
            display: false,
          },
          ticks: {
            color: "white",
            source: "labels",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      layout: {
        padding: {
          top: 10,
        },
      },
      animation: {
        onProgress: () => {
          let ctx = chart.ctx;
          let data = chart.config.data.datasets[0].data;

          // Draw a horizontal line when there is a single data point
          if (data[0] == null && props.lineColor) {
            let xAxis = chart.scales.x!;
            let yAxis = chart.scales.y!;
            let y = yAxis.getPixelForValue(data[1] as number);
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.strokeStyle = props.lineColor;
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(xAxis.left, y);
            ctx.lineTo(xAxis.right, y);
            ctx.stroke();
            ctx.restore();
            ctx.fillStyle = getGradient(ctx, chart.chartArea!);
            ctx.beginPath();
            ctx.moveTo(xAxis.left, y);
            ctx.lineTo(xAxis.right, y);
            ctx.lineTo(xAxis.right, yAxis.bottom);
            ctx.lineTo(xAxis.left, yAxis.bottom);
            ctx.closePath();
            ctx.fill();
          }
        },
      },
    },
  });

  legendChart = new Chart(legendCanvas.value!, {
    type: "line",
    data: {
      datasets: [
        {
          label: "Data",
          data: groupedData.value,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 15,
        },
      },
      scales: {
        y: {
          min: props.min ?? undefined,
          max: props.max ?? undefined,
          title: {
            display: false,
          },
          ticks: {
            stepSize: tickSize.value,
            color: "white",
          },
          grid: {
            drawTicks: false,
          },
          afterFit: (ctx) => {
            ctx.width = 35;
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
    },
  });
});

watch(dateLocale, (newLocale) => {
  if (!chart) return;

  // @ts-expect-error We're setting values as we did in the onMounted so we know they exist
  chart.options!.scales!.x!.time!.displayFormats.day = newLocale === enUS ? "MM/dd" : "dd/MM";
  // @ts-expect-error We're setting values as we did in the onMounted so we know they exist
  chart.options!.scales!.x!.adapters!.date.locale = newLocale;

  chart.update();
});

watch([groupedData, timeTicks], ([newData, newLabels]) => {
  if (!chart || !legendChart) {
    console.warn("Missing chart", { chart, legendChart });
    return;
  }

  if (newData.length !== newLabels?.length) {
    throw new Error(
      `Mismatching number of data points (${newData.length}) and labels (${newLabels?.length ?? "N/A"})`
    );
  }

  if (newData.length === 1) {
    newData = [null, ...newData, null];
    // @ts-expect-error We use null values to center charts when a single value is available
    newLabels = [null, ...(newLabels ?? [null]), null];
  }

  if (!props.labels) {
    chart.data.labels = newData.map((_, idx) => idx + 1);
  }

  chart.data.datasets.forEach((dataset) => {
    dataset.data = newData;
  });

  const dataMin = Math.min(...(newData.filter((d) => d != null) as number[]));
  const dataMax = Math.max(...(newData.filter((d) => d != null) as number[]));

  legendChart.options.scales!.y!.min = Math.floor(dataMin * 0.85);
  legendChart.options.scales!.y!.max = Math.ceil(dataMax + dataMin * 0.15);
  chart.options.scales!.y!.min = Math.floor(dataMin * 0.85);
  chart.options.scales!.y!.max = Math.ceil(dataMax + dataMin * 0.15);

  chart.data.labels = newLabels;

  chart.update();
  legendChart.update();

  setTimeout(() => {
    if (newData.length === 0) return;

    // Resize canvas for scrolling purposes
    const minCanvasWidthPx = chart.canvas.parentElement!.parentElement!.clientWidth;
    const canvasParent = chart.canvas.parentElement!;

    // Ensure the canvas takes full-width (at minimum)
    if (newData.length * colWidthPx > minCanvasWidthPx) {
      canvasParent.style.width = newData.length * colWidthPx + "px";
      chart.update();
    } else if (chart.canvas.parentElement!.clientWidth < minCanvasWidthPx) {
      canvasParent.style.width = minCanvasWidthPx + "px";
      chart.update();
    }
  }, 100);
});

watch(tickSize, (newTickSize) => {
  if (!chart || !legendChart || !newTickSize) return;

  // @ts-ignore Don't know why but stepSize is not recognized as a valid property
  chart.options.scales!.y!.ticks!.stepSize = newTickSize;
  // @ts-ignore Don't know why but stepSize is not recognized as a valid property
  legendChart.options.scales!.y!.ticks!.stepSize = newTickSize;

  chart.update("none");
  legendChart.update("none");
});
</script>

<template>
  <figure class="flex w-full relative h-full">
    <div v-show="data.length !== 0" class="chart-legend absolute left-0 bottom-0 top-0 w-[35px]">
      <canvas ref="legendCanvas" width="100%"></canvas>
    </div>

    <div
      v-show="data.length !== 0"
      class="chart-data overflow-auto absolute left-[35px] bottom-0 top-0 right-0 no-scrollbar"
    >
      <div class="chart-container h-full">
        <canvas ref="chartCanvas" height="100%"></canvas>
      </div>
    </div>

    <div
      v-show="data.length === 0"
      class="absolute top-0 bottom-0 left-0 right-0 grid place-content-center"
    >
      <slot />
    </div>
  </figure>
</template>

<style scoped></style>
