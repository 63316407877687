import { makeApi } from "~/api/index";

import { CreateOrderInput, OrderParameters } from "@opensea/seaport-js/src/types";

export interface IOrderRequest {
  cuid: string;
  data: any;
}

export interface IOrderParamRequest {
  cuid: string;
  bidAmount: string;
}

const baseURL = import.meta.env.VITE_API_URL;
const api = makeApi(baseURL);

export const transactionApi = {
  async getOrderParam(order: IOrderParamRequest): Promise<CreateOrderInput> {
    const { data } = await api.get("bid", { params: order });
    return data as CreateOrderInput;
  },
  async submitOrder(order: IOrderRequest): Promise<any> {
    const { data } = await api.post("bid", {
      ...order.data,
      cuid: order.cuid,
    });
    return data;
  },
};
