import { defineStore } from "pinia";
import { FlashSaleApi } from "~/api/flashSale";
import { IAuctionItem, IFlashSale } from "~/common/interfaces/IMarketplace";
import { displayError } from "~/utils/errors";
import { BigNumber } from "ethers";
import { CardType, AuctionUpdate } from "fungi-types";
import { ASSETS_URL } from "~/application/config";

export const useStoreFlashSale = defineStore("flash-sale", {
  state: () => ({
    api: new FlashSaleApi(),
    availableFlashSales: [] as Array<Omit<IFlashSale, "auction">>,
    flashSale: null as IFlashSale | null,
    loading: false,
    backgroundImg: "",
    bannerImg: "",
  }),
  getters: {
    auctions: (state): IFlashSale["auction"] => {
      if (!state.flashSale || !state.flashSale.auction) return [];
      return state.flashSale.auction;
    },
    homeFlashSale: (state): Omit<IFlashSale, "auction"> | undefined => {
      return state.availableFlashSales[0];
    },
    auctionsSummary(): {
      highest: IAuctionItem | null;
      lowest: IAuctionItem | null;
      mostOrders: IAuctionItem | null;
    } {
      const auctions = this.auctions;
      if (!auctions.length) {
        return { highest: null, lowest: null, mostOrders: null };
      }

      return auctions.reduce(
        ({ highest, lowest, mostOrders }, auction) => {
          const currentBid = BigNumber.from(auction.lastBidAmount ?? "0");
          const highestBid = BigNumber.from(highest?.lastBidAmount ?? "0");
          const lowestBid = BigNumber.from(lowest?.lastBidAmount ?? "0");

          const currentOrders = auction.countOrders;
          const mostOrdersCount = mostOrders?.countOrders ?? 0;
          return {
            highest: !highest || currentBid.gt(highestBid) ? auction : highest,
            lowest:
              !lowest || lowestBid.isZero() || (currentBid.lt(lowestBid) && auction.lastBidAmount)
                ? auction
                : lowest,
            mostOrders: currentOrders > mostOrdersCount ? auction : mostOrders,
          };
        },
        { highest: auctions[0], lowest: auctions[0], mostOrders: auctions[0] }
      );
    },
    recentActivity(): IAuctionItem[] {
      const auctions = this.auctions;
      if (!auctions.length) return [];

      return auctions
        .slice() // clone array
        .filter((auction) => auction.lastBidAt)
        .sort((a, b) => {
          const dateA = a.lastBidAt ? new Date(a.lastBidAt).getTime() : 0;
          const dateB = b.lastBidAt ? new Date(b.lastBidAt).getTime() : 0;
          return dateB - dateA;
        })
        .slice(0, 8);
    },
  },
  actions: {
    async getAvailableFlashSales() {
      this.availableFlashSales = await this.api.availableFlashSales();
      this.bannerImg = this.setBackgroundImg(this.availableFlashSales[0] as IFlashSale, "banner");
    },
    async findOne(identifier: string) {
      try {
        this.loading = true;
        const flashSale = await this.api.findOne(identifier);
        flashSale.auction.forEach((auction) => {
          if (flashSale.config.type === CardType.NftEquipment) {
            auction.card = auction.equipment!;
          }
          auction.type = flashSale.config.type;
        });
        this.flashSale = flashSale;
        this.backgroundImg = this.setBackgroundImg(flashSale, "background");
      } catch (e: any) {
        if (e.response && e.response.status === 404) {
          displayError("Page not found");
        } else {
          displayError(e);
        }
      }
      this.loading = false;
    },

    setBackgroundImg(flashSale: IFlashSale, file: "banner" | "background"): string {
      if (!flashSale) return "";
      return `url("${ASSETS_URL}/flashsale/${flashSale.cuid}/${file}.webp")`;
    },

    updateAuction(
      data: AuctionUpdate
    ): { prevBidder: IAuctionItem["lastBidder"]; item: IAuctionItem } | null {
      let prevBidder: { username: string | undefined; cuid: string | undefined } | null = null;
      let updatedAuction: IAuctionItem | null = null;
      this.$patch((state) => {
        const auction = state.flashSale?.auction.find(
          (auction) => auction.cuid === data.auction.cuid
        );
        if (!auction) return;
        prevBidder = auction.lastBidder;
        const { lastBidAt, lastBidAmount, status, countOrders, endedAt } = data.auction;
        Object.assign(auction, { lastBidAt, lastBidAmount, status, countOrders, endedAt });
        auction.lastBidder = data.bidder;
        updatedAuction = auction;
      });
      if (!updatedAuction) return null;
      return { prevBidder, item: updatedAuction };
    },
  },
});
