<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import { ArrowLeft } from "lucide-vue-next";
import { storeToRefs } from "pinia";
import { toRefs } from "vue";

const props = withDefaults(
  defineProps<{
    withoutSkip?: boolean;
    mobileColFooter?: boolean;
    continueTextKey?: string;
    hideOkButton?: boolean;
    okButtonDisabled?: boolean;
  }>(),
  {
    withoutSkip: false,
    mobileColFooter: false,
    continueTextKey: "ok",
    hideOkButton: false,
    okButtonDisabled: false,
  }
);

const { continueTextKey, withoutSkip, mobileColFooter, hideOkButton, okButtonDisabled } =
  toRefs(props);

const { t } = useI18n();
const onboardingStepsStore = useOnboarding();
const { goToPreviousStep, goToNextStep, exitOnboarding, totalSteps } = onboardingStepsStore;
const { step } = storeToRefs(onboardingStepsStore);
</script>

<template>
  <div class="flex justify-between items-center gap-2 lg:gap-4">
    <div class="hidden lg:flex font-light text-center">
      {{ t("onboarding.pages.outOf", [step, totalSteps]) }}
    </div>

    <div class="grow flex justify-between gap-4">
      <button
        v-if="!withoutSkip"
        class="font-semibold hidden lg:block text-center"
        @click="exitOnboarding"
      >
        {{ t("onboarding.skip") }}
      </button>

      <div
        :class="`grow flex ${mobileColFooter ? 'flex-col' : ''} md:flex-row justify-end items-center gap-6`"
      >
        <slot name="back-button">
          <button @click.prevent.stop="goToPreviousStep">
            <ArrowLeft is="button" />
          </button>
        </slot>

        <button
          v-show="!hideOkButton"
          class="bg-primary-500 hover:bg-primary-60 disabled:grayscale transition-all duration-300 px-4 py-2 rounded-md w-full md:w-auto md:px-16 lg:px-12 lg:w-auto whitespace-nowrap"
          :disabled="okButtonDisabled"
          @click.prevent.stop="goToNextStep"
        >
          {{ t(continueTextKey) }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
