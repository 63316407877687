import { Contract, ethers } from "ethers";
import { ProviderType } from "@/application/enums/ProviderType.enum";
import { rpcUrlsMainnet, rpcUrlsMumbai, isProd } from "@/application/config";

export class ContractService<T> {
  private infuraAPIKey = import.meta.env.VITE_INFURA_API_KEY as string;

  public contract!: T;
  public rawContract!: Contract;
  public contractAddress: string;
  public userProvider: any;
  public providerType = ProviderType.NONE;
  public abi: ethers.ContractInterface;

  constructor(address: string, abi: ethers.ContractInterface) {
    this.contractAddress = address;
    this.abi = abi;
  }

  private async connectEthers(provider: any): Promise<T> {
    try {
      this.rawContract = new Contract(
        this.contractAddress,
        this.abi,
        provider
      ) as unknown as Contract;

      const name = await this.rawContract.name();

      return Promise.resolve(this.rawContract as unknown as T);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Init Provider connection and instantiate Contract
   * @returns
   */
  public async init(): Promise<T> {
    // if user already connected, connect using user provider
    if (this.userProvider) {
      await this.connectUserProvider(this.userProvider);
      this.providerType = ProviderType.WEB3;
      return Promise.resolve(this.contract);
    }
    // connect using ethers
    const rpcList = isProd ? rpcUrlsMainnet : rpcUrlsMumbai;
    const providers: ethers.providers.JsonRpcProvider[] = [];

    for (let i = 0; i < rpcList.length; i++) {
      const url = rpcList[i].replace("${INFURA_API_KEY}", this.infuraAPIKey);
      providers.push(new ethers.providers.JsonRpcProvider(url));
    }
    this.contract = await Promise.any(providers.map((provider) => this.connectEthers(provider)));
    this.providerType = ProviderType.RPC;
    return Promise.resolve(this.contract);
  }

  public async connectRpcProvider(): Promise<T> {
    this.userProvider = null;
    return this.init();
  }

  public async connectUserProvider(provider: any): Promise<T> {
    try {
      // this.contract = await this.connectEthers(new ethers.providers.Web3Provider(provider).getSigner())
      this.contract = await this.connectEthers(provider.getSigner());
      this.userProvider = provider;
      // const name = await this.contract.name()
      this.providerType = ProviderType.WEB3;
      return Promise.resolve(this.contract);
    } catch (error) {
      return this.connectRpcProvider();
    }
  }
}
