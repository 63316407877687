<script setup lang="ts">
import { useRoute } from "vue-router";
import Trans from "~/modules/translations";
import { TrendingUp, WalletMinimal } from "lucide-vue-next";

const route = useRoute();
</script>

<template>
  <nav class="nav-container">
    <div class="overflow-hidden flex items-center justify-evenly gap-0">
      <router-link :to="Trans.i18nRoute({ name: 'MyAccount' })" class="nav-item">
        <div class="icon-container h-4">
          <WalletMinimal class="p-0" />
        </div>
        <span class="truncate">{{ $t("nav.wallet") }}</span>
      </router-link>
      <router-link :to="Trans.i18nRoute({ name: 'Dashboards' })" class="nav-item overflow-hidden">
        <div class="icon-container h-4">
          <TrendingUp />
        </div>
        <span>{{ $t("pages.dashboard.title") }}</span>
      </router-link>
    </div>
    <router-link :to="Trans.i18nRoute({ name: 'Competitions' })" class="nav-item relative z-10">
      <icon-fg-logo class="absolute left-1/2 -translate-x-1/2 -top-1" />
      <div class="icon-container"></div>
      <span>{{ $t("nav.play") }}</span>
    </router-link>
    <div class="flex items-center justify-evenly relative">
      <router-link :to="Trans.i18nRoute({ name: 'MyCards' })" class="nav-item overflow-hidden">
        <div class="icon-container">
          <icon-fg-cards />
        </div>
        <span>{{ $t("navigation.MyDeck") }}</span>
      </router-link>
      <router-link :to="Trans.i18nRoute({ name: 'NewCards' })" class="nav-item overflow-hidden">
        <div class="icon-container">
          <icon-fg-bag />
        </div>
        <span>{{ $t("navigation.Market") }}</span>
      </router-link>
    </div>
    <div class="half-circle"></div>
  </nav>
</template>

<style scoped lang="postcss">
.half-circle {
  @apply absolute w-[100px] h-[50px] rounded-t-[50px] rounded-b-none bg-primary-110 left-1/2 -translate-x-1/2 -translate-y-1/2 border-2 border-b-0 border-white/20;
  &:after {
    content: "";
    @apply absolute w-[101px] h-[25px] bottom-0 bg-primary-110 -left-[2px];
  }
}
.nav-container {
  @apply lg:hidden grid text-xs fixed capitalize font-medium text-grey-70 px-f1 bottom-0 left-0 right-0 bg-primary-110 h-[57px] border-t-2 border-white/20;
  grid-template-columns: 1fr 80px 1fr;
}

.nav-item {
  @apply flex flex-col gap-f1 items-center justify-center;
}
.icon-container {
  @apply w-f3 aspect-square flex items-center;
}

span {
  @apply truncate max-w-full;
}
</style>
