import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { NftType } from "fungi-types";

export function NftTypeMiddleware(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  _type?: NftType
) {
  const validTypes = Object.values(NftType);
  const type = to.params.type;
  if (!type || !validTypes.includes(type.toString() as NftType)) {
    return next({
      name: to.name as string,
      params: { ...to.params, type: _type ?? NftType.SKILL },
    });
  }
  return next();
}
