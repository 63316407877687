<script setup lang="ts">
import { useI18n } from "vue-i18n";
import Trans from "~/modules/translations";
const { t } = useI18n();

const route = useRoute();

import HeaderSkeleton from "./HeaderSkeleton.vue";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { MarketplaceApi } from "~/api/marketplace";
import { Scarcity, MercureEventType, MarketType } from "fungi-types";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";

import { usePrimaryMarketplace } from "~/stores/storePrimaryMarketplace";

import { MercureService } from "~/services/mercureService";

const contents = [
  {
    background: "/images/headers/marketplace.jpg",
    title: t("pages.marketplace.headers.primary.title"),
    description: t("pages.marketplace.headers.primary.description"),
  },
  {
    background: "/images/headers/marketplace.jpg",
    title: t("pages.marketplace.headers.secondary.title"),
    description: t("pages.marketplace.headers.secondary.description"),
  },
];

const api = new MarketplaceApi(MarketType.PRIMARY);
const auctions = ref<IAuctionItem[]>([]);
const store = usePrimaryMarketplace();

const content = computed(() => {
  switch (route.name) {
    case "NewCards":
      return contents[0];
    case "Trade":
      return contents[1];
    default:
      return contents[0];
  }
});

const findAndMoveToFirst = <T,>(arr: T[], predicate: (item: T) => boolean): T[] => {
  const index = arr.findIndex(predicate);

  if (index === -1) {
    return arr; // Element not found, return original array
  }

  const newArr = [...arr];
  const [element] = newArr.splice(index, 1);
  newArr.unshift(element);

  return newArr;
};

onMounted(async () => {
  const filters = { scarcity: [Scarcity.PLATINUM] };
  const { data, meta } = await api.getCards(filters);
  auctions.value = findAndMoveToFirst(
    data,
    (auction) => auction.cuid === "cm2dcw0cb003j0fs6bz0mavsi"
  );

  const mercureService = MercureService.getInstance();
  mercureService.emitter.on(MercureEventType.AUCTION_UPDATE, async (event) => {
    const updated = await store.updateAuction(event, auctions.value);
    if (updated && updated.index !== -1) {
      auctions.value[updated.index] = updated.item;
    }
  });
});

const showHeader = ref(false);
</script>

<template>
  <HeaderSkeleton
    :showHeader="showHeader"
    :background="content.background"
    :title="content.title"
    :subtitle="content.description"
  >
    <template #featuring>
      <div
        class="h-full w-full bg-cover bg-center bg-no-repeat flex justify-center flex-col z-10 relative"
        :style="{ backgroundImage: `url('/images/headers/platinum.jpg')` }"
      >
        <div
          class="flex md:flex-row flex-col mt-32 lg:mt-40 w-full place-items-stretch mb-16 justify-between overflow-y-scroll md:overscroll-none scrollbar-hide"
        >
          <div class="items-center md:flex">
            <div
              class="flex flex-col gap-6 px-10 mb-10 md:pb-20 md:min-w-[400px] lg:min-w-[600px] max-w-[800px] mr-10"
            >
              <div class="text-4xl font-semibold">
                {{ t("pages.marketplace.headers.featuring.platinum.title") }}
              </div>
              <div class="flex flex-col gap-1">
                <div class="font-semibold text-lg">
                  {{ t("pages.marketplace.headers.featuring.platinum.subtitle") }}
                </div>
                <div class="text-sm">
                  {{ t("pages.marketplace.headers.featuring.platinum.description") }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="place-self-start col-span-2 overflow-y-scroll scrollbar-hide pl-10 md:pl-0 w-full"
          >
            <div class="relative grid grid-flow-col">
              <div v-for="(auction, index) in auctions.slice(0, 10)" class="w-56 mr-6">
                <AuctionItem :key="index" :item="auction" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row gap-2 px-4 text-sm relative">
          <router-link
            :to="Trans.i18nRoute({ name: 'NewCards', params: $route.params })"
            class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0 market-type transition-all"
            >{{ t("pages.marketplace.headers.primary.tab") }}</router-link
          >
          <router-link
            :to="Trans.i18nRoute({ name: 'Trade', params: $route.params })"
            class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0 market-type transition-all"
            >{{ t("pages.marketplace.headers.secondary.tab") }}</router-link
          >
        </div>
        <div class="h-[1px] bg-slate-600 w-full absolute bottom-0 z-20"></div>
      </div>
    </template>
  </HeaderSkeleton>
</template>

<style scoped>
.router-link-exact-active {
  @apply !border-slate-600 !bg-black transition-colors z-30;
}

.market-type:hover {
  @apply hover:bg-slate-800;
}

.router-link-exact-active.market-type:hover {
  @apply hover:bg-slate-700;
}
</style>
