import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import type { AppRouter } from "api-competitions";
import { userToken } from "~/utils/localStorage";
const baseUrl = import.meta.env.VITE_GAME_API_URL;

export const gameApi = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: baseUrl,
      async headers() {
        return {
          authorization: "Bearer " + userToken.get(),
        };
      },
    }),
  ],
});
