<script setup lang="ts">
const props = defineProps<{
  disabled?: boolean;
  blue?: boolean;
}>();

const emits = defineEmits(["click"]);
</script>

<template>
  <button
    @click.stop="emits('click')"
    class="py-2 focus:outline-none font-sans rounded-full font-medium tracking-wide disabled:bg-neutral-900 disabled:text-neutral-400 disabled:border-neutral-600 border-[1px] transition-colors ease-in duration-100"
    :class="{
      'bg-zinc-900 border-slate-600 hover:bg-white hover:text-black': !props.blue,
      'bg-blue-700 border-blue-700 hover:bg-blue-600 ': props.blue,
    }"
    :disabled="props.disabled"
  >
    <slot />
  </button>
</template>
