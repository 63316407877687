import { createI18n, I18nOptions } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";
const options: I18nOptions = {
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  globalInjection: true,
  availableLocales: ["en", "fr"],
  messages,
};

const i18n = createI18n(options);

export default i18n;
