import { Seaport } from "@opensea/seaport-js";
import type { CreateOrderInput, OrderWithCounter } from "@opensea/seaport-js/lib/types";
import type { SafeEventEmitterProvider } from "@web3auth/base";
import { seaportConf, seaportConduitKey } from "~/application/config";
import { Signer, ethers } from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";
import type { JsonRpcSigner } from "@ethersproject/providers";

export class SeaportService {
  public seaport: Seaport;

  constructor(signer: any) {
    if (!signer) throw new Error("signer is null !");
    try {
      // const signer = provider.
      // this.seaport = new Seaport(signer as any, { seaportVersion: "1.5" });
      // const provider = new ethers.BrowserProvider(window.ethereum);

      // const prv = new ethers.providers.Web3Provider(provider);

      this.seaport = new Seaport(signer, {
        seaportVersion: "1.5",
        conduitKeyToConduit: seaportConf,
        overrides: {
          defaultConduitKey: seaportConduitKey,
        },
      });
    } catch (error) {
      console.error("Seaport initialisation Failure", error);
      throw error;
    }
  }

  public async getOrderSignature(
    orderParams: CreateOrderInput,
    offerer: string,
    fulfiller?: string
  ): Promise<OrderWithCounter> {
    try {
      const { executeAllActions } = await this.seaport.createOrder(orderParams, offerer);
      return await executeAllActions();
    } catch (error) {
      console.error("createOrder error: ", error);
      throw error;
    }
  }
}
