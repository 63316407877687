<script setup lang="ts">
import { CardDeck } from "~/common/interfaces/IUser";
import { computed } from "vue";
import { BigNumber } from "ethers";
import { AuctionStatus } from "~/common/enums/auction";
import { CardType, MarketType, NftSkill as TNftSkill } from "fungi-types";
import { useCardDetails } from "../card-details/use-card-details";
import CardDataFooterSkeleton from "../card/cardDataFooterSkeleton.vue";
import CardDataFooterScore from "../card/cardDataFooterScore.vue";
import CardDataFooterPrice from "../card/cardDataFooterPrice.vue";

const props = withDefaults(
  defineProps<{
    showCardDetails: boolean;
    item: CardDeck;
    loading?: boolean;
    showFooter?: boolean;
  }>(),
  {
    showCardDetails: true,
    showFooter: true,
  }
);

const emits = defineEmits<{
  (event: "list", item: CardDeck): void;
  (event: "unlist", item: CardDeck): void;
}>();

const showAuction = computed(() => {
  return props.item.auction && props.item.auction.status !== AuctionStatus.ENDED;
});

const cardDetails = useCardDetails();
const navigateToCardDetails = (cardToShowTheDetailsFor: TNftSkill) => {
  cardDetails.loadCard(cardToShowTheDetailsFor.tokenId, cardToShowTheDetailsFor);
  cardDetails.showModal = true;
};

const handleClickOnCard = () => {
  if (props.showCardDetails) navigateToCardDetails(props.item);
};

const _weiAmount = computed(() => {
  if (!props.item.auction) return null;
  return BigNumber.from(props.item?.auction?.lastBidAmount ?? props.item.auction?.startedAmount);
});
</script>

<template>
  <NftSkill :nft="item" @click="handleClickOnCard">
    <template #footer-data>
      <CardDataFooterSkeleton>
        <template #header>
          <CardDataFooterScore
            :l10="item.score"
            :l5="item.l5"
            :skill="item.skill"
            :token-id="item.tokenId"
            :scarcity="item.scarcity"
            :court="item.courtType"
            :season="item.season"
            :xp="item.xp"
          />
        </template>

        <CardDataFooterPrice
          v-if="showAuction && _weiAmount && item.auction"
          :weiAmount="_weiAmount"
          :marketType="MarketType.SECONDARY"
          :cuid="item.auction.cuid"
          :endedAt="item.auction.endedAt"
          :status="item.auction.status"
          lastBidder=""
        />
      </CardDataFooterSkeleton>
    </template>
    <template #cta v-if="showFooter && (!item.card_type || item.card_type === CardType.NftSkills)">
      <ButtonPrimary
        v-if="item.auction && item.auction.status === AuctionStatus.ENDED"
        disabled
        @click.stop
      >
        <span> {{ $t("pages.marketplace.trade.sold") }} </span>
      </ButtonPrimary>
      <ButtonPrimary v-else-if="item.auction" @click="emits('unlist', item)" @click.stop>
        <span
          v-if="loading || item.auction.status === AuctionStatus.PENDING_TO_BE_EXECUTED"
          class="py-[2px]"
        >
          <icon-fgc-loading />
        </span>
        <span v-else> {{ $t("pages.myDeck.unlist") }} </span>
      </ButtonPrimary>

      <ButtonPrimary v-else @click="emits('list', item)" @click.stop>
        <span v-if="loading" class="py-[2px]">
          <icon-fgc-loading />
        </span>
        <span v-else> {{ $t("pages.myDeck.list") }} </span>
      </ButtonPrimary>
    </template>
  </NftSkill>
</template>

<style scoped lang="postcss"></style>
