<script setup lang="ts">
import { Power1, gsap } from "gsap";
import { computed, onMounted, ref } from "vue";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";
import { scarcityStyle, typeStyle } from "~/composables/color";
import { ThumbSize, scaledNftImg } from "~/composables/useThumbs";
import { useStoreApp, NotifType } from "~/stores/storeApp";
import FlashSaleCard from "~/components/marketplace/FlashSaleCard.vue";
import { nextTick } from "process";
import { CardType } from "fungi-types";
const storeApp = useStoreApp();

const extratext = ref("");
const auctionItem = ref<IAuctionItem | null>(null);

const tl = gsap.timeline();
onMounted(() => {
  storeApp.emitter.on("notif", (event) => {
    extratext.value = event.text ? event.text : "";
    auctionItem.value = event.auctionItem;
    nextTick(() => {
      displayNotif(event.type);
    });
  });
});

function displayNotif(type: NotifType) {
  const textId = `#notif-${type}`;

  const stayTime = type === NotifType.WIN ? 4 : 2;

  tl.set(textId, { right: "100%", display: "flex" });
  tl.set("#strip", { width: "0%", right: "100%" });
  tl.set("#notifScreen", { display: "block" });
  // if (auctionItem.value?.card) {
  tl.set(".img-nft", { translateX: "-50vw" });
  // }
  tl.to("#strip", {
    duration: 0.5,
    width: "100%",
    right: "0%",
    ease: Power1.easeInOut,
  });
  tl.to(
    textId,
    {
      duration: 0.4,
      right: "25%",
      ease: Power1.easeInOut,
    },
    "-=0.2"
  );
  if (auctionItem.value?.card) {
    tl.to(
      ".img-nft",
      {
        duration: 0.2,
        translateX: "0",
        ease: Power1.easeInOut,
      },
      "-=0.1"
    );
    tl.to(".img-nft", {
      duration: 0.1,
      scale: 1.1,
      ease: Power1.easeInOut,
    });
    tl.to(".img-nft", {
      duration: 0.1,
      scale: 1,
      ease: Power1.easeInOut,
    });
  }
  // out

  tl.to(textId, {
    duration: 0.5,
    delay: stayTime,
    right: "-100%",
    ease: Power1.easeInOut,
  });

  if (auctionItem.value?.card) {
    tl.to(
      ".img-nft",
      {
        duration: 0.2,
        translateX: "-500px",
        ease: Power1.easeInOut,
      },
      "<"
    );
  }
  tl.to(
    "#strip",
    {
      duration: 0.5,
      width: "0%",
      right: "-100%",
      ease: Power1.easeInOut,
    },
    "-=0.3"
  );
  tl.set(textId, { display: "none" });
  tl.set("#notifScreen", { display: "none" });
}
</script>

<template>
  <teleport to="body">
    <div
      id="notifScreen"
      class="fixed h-screen w-screen top-0 left-0 overflow-hidden pointer-events-none hidden z-50 font-display italic"
    >
      <div class="relative flex items-center justify-center top-f7 text-center">
        <div
          id="strip"
          class="absolute overflow-hidden flex flex-col items-center justify-center w-full h-f7 bg-black/90"
        ></div>

        <div id="notif-outbid" class="notif-banner">
          <img
            v-if="auctionItem?.card"
            :src="scaledNftImg(auctionItem?.card.image, ThumbSize.S)"
            class="img-nft h-[100px] p-2 mr-2"
            alt="nft"
          />
          <span class="whitespace-pre-wrap text-red-500">{{
            $t("messages.outbid", { n: extratext })
          }}</span>
        </div>
        <div id="notif-topbid" class="notif-banner">
          <span class="whitespace-pre-wrap">{{ $t("messages.bid-success") }}</span>
        </div>
        <div id="notif-overbid" class="notif-banner">
          <span class="whitespace-pre-wrap">{{ $t("messages.bid-over") }}</span>
        </div>

        <div id="notif-win" class="notif-banner">
          <img
            v-if="auctionItem?.card"
            :src="scaledNftImg(auctionItem?.card.image, ThumbSize.S)"
            class="img-nft h-[100px] p-2 mr-2"
            alt="nft"
          />
          <div class="flex flex-col lg:flex-col">
            <span class="whitespace-pre-wrap">{{ $t("messages.bid-win", { n: extratext }) }}</span>

            <span v-if="auctionItem && auctionItem.type === CardType.NftEquipment"
              >{{ auctionItem.equipment?.title }}
            </span>
            <span
              v-else-if="auctionItem"
              :style="{ color: typeStyle(auctionItem?.card.skill).backgroundColor }"
              >{{ auctionItem.card.firstName }} {{ auctionItem.card.lastName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<style scoped lang="postcss">
.notif-banner {
  @apply absolute w-1/2 flex-row gap-1 items-center hidden justify-center text-lg lg:text-2xl 2xl:text-[30px];
}
</style>
