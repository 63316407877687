<script setup lang="ts">
import { computed, provide, ref } from "vue";
import { storeToRefs } from "pinia";
import { useCardDetails } from "./use-card-details";
import { Skill } from "fungi-types";
import SkillSwitchTabs from "~/components/card-details/SkillSwitchTabs.vue";
import OwnersHistorySection from "~/components/card-details/OwnersHistory/OwnersHistorySection.vue";
import AvailableCardsSection from "~/components/card-details/AvailableCards/AvailableCardsSection.vue";
import MarketplaceInfoSection from "~/components/card-details/MarketplaceInfo/MarketplaceInfoSection.vue";
import ScoresSection from "~/components/card-details/Scores/ScoresSection.vue";
import PlayerInfo from "~/components/card-details/PlayerInfo/PlayerInfo.vue";
import CardDetailsSkeleton from "./CardDetailsSkeleton.vue";
import AuctionItem from "~/components/marketplace/AuctionItem.vue";
import DeckItem from "../myDeck/DeckItem.vue";
const props = defineProps<{
  isModal?: boolean;
  hideBackground?: boolean;
}>();

const cardDetails = useCardDetails();
const { card, cardSales, scores, skill: currentSkill } = storeToRefs(cardDetails);

const cardAuction = computed(() => {
  return cardSales.value.find((auction) => auction?.card.tokenId === card?.value?.tokenId);
});

const auctionItem = computed(() => {
  const checkCard = cardSales.value.filter(
    (auction) => auction?.card.lastName === card?.value?.lastName
  );
  return cardAuction.value ?? checkCard[0];
});

const skillsGradients: Record<Skill, [string, string]> = {
  [Skill.SERVICE]: ["#A42A2700", "#A42A27CC"],
  [Skill.POWER]: ["#E39E1200", "#E39E12CC"],
  [Skill.RETURN]: ["#33AAFF00", "#33AAFFCC"],
  [Skill.MENTAL]: ["#53AA3300", "#53AA33CC"],
};
provide("skills-gradients", skillsGradients);

const skillsColors: Record<Skill, string> = {
  [Skill.SERVICE]: "#A42A27",
  [Skill.POWER]: "#E39E12",
  [Skill.RETURN]: "#33AAFF",
  [Skill.MENTAL]: "#53AA33",
};
provide("skills-colors", skillsColors);

const changeSkill = (skill: Skill) => {
  cardDetails.skill = skill;
};

const handleCloseCardDetails = () => {
  cardDetails.showModal = false;
};
</script>

<template>
  <CardDetailsSkeleton @close="handleCloseCardDetails">
    <template #aside>
      <div v-if="!cardDetails.loadingSales">
        <DeckItem
          :showFooter="false"
          v-if="cardDetails.placeholderCard"
          :item="cardDetails.placeholderCard"
        />
        <AuctionItem
          v-else-if="auctionItem"
          :showCardDetails="false"
          :item="auctionItem"
          :currently-available="cardSales.length"
        />
      </div>
    </template>
    <template #header>
      <div class="flex flex-col gap-4">
        <PlayerInfo class="mb-2 w-full" />
        <SkillSwitchTabs
          class="self-stretch"
          :active-skill="currentSkill!"
          :skill-scores="cardDetails.l10Scores"
          @change="changeSkill"
        />
      </div>
    </template>
    <template #main>
      <div class="flex flex-col gap-6 py-6">
        <AuctionItem
          v-if="auctionItem"
          class="block md:hidden"
          :item="auctionItem"
          :currently-available="cardSales.length"
        />
        <ScoresSection />

        <MarketplaceInfoSection />

        <AvailableCardsSection />

        <!-- <OwnersHistorySection /> -->
      </div>
    </template>
  </CardDetailsSkeleton>
</template>

<style>
/* The following styles are sent to the children components */
</style>
