<script lang="ts">
import BidPanel from "@/components/panel/BidPanel.vue";
export default {
  components: {
    BidPanel,
  },
};
</script>
<script setup lang="ts">
import usePanel from "@/composables/usePanel";
import { nextTick, ref, watch } from "vue";

const { panelProps, panelComponent, panelTeleport, closeModal } = usePanel;
const displayContent = ref(false);

const showModal = ref<null | string>(null);

function displayModal(modal: string | null) {
  showModal.value = modal;
}

function close() {
  closeModal();
  displayModal(null);
}

watch(panelComponent, () => {
  nextTick(() => {
    displayContent.value = !displayContent.value;
  });
});
</script>

<template>
  <div v-if="panelComponent">
    <teleport :to="panelTeleport">
      <div
        v-if="!showModal"
        @click="closeModal"
        class="fixed z-50 inset-0 bg-black/60 overflow-hidden"
      >
        <Transition name="slide">
          <div
            v-if="displayContent"
            @click.stop
            class="absolute bg-black w-full sm:w-[330px] 2xl:w-[430px] h-full top-0 right-0"
          >
            <div
              @click="closeModal"
              class="w-full flex gap-2 items-center cursor-pointer px-2 py-3"
            >
              <icon-material-symbols:add class="text-greyMed rotate-45 w-[24px] h-[24px]" />
              <span class="uppercase font-display text-xxs text-greyMed">{{
                $t("button.close")
              }}</span>
            </div>
            <div class="px-3">
              <component :is="panelComponent" v-bind="panelProps" />
            </div>
          </div>
        </Transition>
      </div>
      <Modal :show="showModal !== null" invert @closed="close" :has-close-button="false">
        <div class="flex flex-col items-center justify-center gap-10 w-[500px] h-[300px]">
          <span v-if="showModal === 'success-bid'">{{ $t("messages.bid-success") }}</span>
          <span v-if="showModal === 'success-list'">{{ $t("messages.list-success") }}</span>
          <button class="btn-primary h-10 w-1/2" @click.stop="close">close</button>
        </div>
      </Modal>
    </teleport>
  </div>
</template>

<style scoped>
.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
