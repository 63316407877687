<script setup lang="ts">
import { ArrowLeft } from "lucide-vue-next";
import { onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import AccountWidget from "~/components/AccountWidget.vue";
import { useEventObserver } from "./scrollObserver";

// Scrollbar
import { OverlayScrollbarsComponent, useOverlayScrollbars } from "overlayscrollbars-vue";
import type { OverlayScrollbarsComponentRef } from "overlayscrollbars-vue";

const { t } = useI18n();

const emit = defineEmits<{
  (event: "close"): void;
}>();

const handleCloseCardDetails = () => {
  emit("close");
};

const handleKeypressed = (event: KeyboardEvent) => {
  if (event.key === "Escape") handleCloseCardDetails();
};

const elementHidden = ref(false);
const bodyOverlayScrollbarsApplied = ref<boolean | null>(null);
const cardDetailMain = ref<OverlayScrollbarsComponentRef | null>(null);
const [_activeEvents, activateEvent] = useEventObserver();
const [initBodyOverlayScrollbars] = useOverlayScrollbars({
  defer: true,
  events: {
    initialized: () => {
      bodyOverlayScrollbarsApplied.value = true;
    },
    destroyed: () => {
      bodyOverlayScrollbarsApplied.value = false;
    },
  },
  options: {
    scrollbars: {
      theme: "scrollbar-cardDetail",
      clickScroll: true,
    },
  },
});

const element = cardDetailMain.value?.getElement();

onMounted(() => {
  document.body.classList.add("overflow-hidden");
  window.addEventListener("keydown", handleKeypressed);
  if (element) {
    initBodyOverlayScrollbars({
      target: element,
    });
  }
});

onUnmounted(() => {
  document.body.classList.remove("overflow-hidden");
  window.removeEventListener("keydown", handleKeypressed);
});
</script>

<template>
  <div
    :class="[
      'z-50 fixed inset-0 flex card-details-container flex-col px-4 pb-0 items-center bg-black',
    ]"
    :style="{ backgroundImage: 'url(/backgrounds/card-details-background.jpeg)' }"
  >
    <div class="flex flex-row w-full justify-between py-6 items-center">
      <div
        @click="handleCloseCardDetails"
        class="flex flex-row gap-2 cursor-pointer hover:underline"
      >
        <ArrowLeft class="w-4" /> {{ t("back") }}
      </div>
      <div><AccountWidget /></div>
    </div>
    <div class="w-full h-full grid grid-flow-row relative max-w-[1200px]">
      <div class="grid grid-cols-12 absolute inset-0 gap-6">
        <div class="bg-red-10 col-span-4 hidden md:block">
          <slot name="aside"></slot>
        </div>
        <div class="col-span-12 md:col-span-8 flex flex-col">
          <slot name="header">
            <!-- Headers goes here -->
          </slot>
          <div class="h-full relative">
            <div class="overflow-auto absolute inset-0">
              <OverlayScrollbarsComponent
                class="overlayscrollbars-vue overflow-auto absolute inset-0"
                ref="cardDetailMain"
                :style="{ display: elementHidden ? 'none' : undefined }"
                :options="{
                  scrollbars: {
                    theme: 'os-theme-light',
                  },
                }"
                :events="{
                  initialized: () => activateEvent('initialized'),
                  destroyed: () => activateEvent('destroyed'),
                  updated: () => activateEvent('updated'),
                  scroll: () => activateEvent('scroll'),
                }"
                defer
              >
                <slot name="main"><!-- Body goes here --></slot>
              </OverlayScrollbarsComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.os-scrollbar-vertical {
  position: fixed;
}
</style>
