<script setup lang="ts">
import { Info } from "lucide-vue-next";
import { computed } from "vue";

interface Props {
  currentLevel: number;
  maxLevels?: number[];
}

const props = withDefaults(defineProps<Props>(), {
  maxLevels: () => [1, 2, 3, 4, 5],
});

const isLevelActive = (level: number) => {
  return props.currentLevel >= level;
};

const progressWidth = computed(() => {
  if (!props.currentLevel) return "0%";
  return `${((props.currentLevel - 1) / (props.maxLevels.length - 1)) * 100}%`;
});
</script>

<template>
  <div class="flex flex-col gap-3.5">
    <span class="text-xl font-medium">{{ $t("pages.myEquipments.modal.myLevels") }}</span>
    <div class="relative">
      <div class="h-2 bg-slate-700 rounded-full mb-4 border border-slate-600"></div>
      <div
        class="absolute top-0 h-2 bg-gradient-to-r from-[#0076CA] from-75% to-[#3AF] to-100% rounded-full transition-all duration-300"
        :style="{ width: progressWidth }"
      ></div>
      <div class="relative h-16">
        <div
          v-for="(level, index) in maxLevels"
          :key="level"
          class="absolute flex flex-col items-center"
          :style="{
            left: `${((level - 1) / (maxLevels.length - 1)) * 100}%`,
            transform:
              index === 0
                ? 'translateX(-40%)'
                : index === maxLevels.length - 1
                  ? 'translateX(-60%)'
                  : 'translateX(-50%)',
          }"
        >
          <div
            class="absolute -top-4 w-0.5 h-4"
            :class="[isLevelActive(level) ? 'bg-primary-60' : 'bg-slate-600']"
          ></div>

          <div
            class="py-1 px-2 flex justify-center text-sm relative rounded-tl-lg rounded-br-lg"
            :class="[isLevelActive(level) ? 'bg-primary-60' : 'bg-slate-600']"
          >
            <span
              class="text-sm font-sans font-semibold whitespace-nowrap flex-nowrap items-center"
            >
              Niv {{ level }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-1">
      <Info width="21" height="21" />
      <span class="font-sans font-normal text-xs leading-5">{{
        $t("pages.myEquipments.modal.information")
      }}</span>
    </div>
  </div>
</template>
