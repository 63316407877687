<script setup lang="ts">
const props = defineProps<{
  name: string;
}>();
</script>

<template>
  <section class="border-slate-700 border rounded-md p-4">
    <header
      class="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 lg:gap-0"
    >
      <h2 class="grow font-semibold">{{ props.name }}</h2>

      <slot name="time-picker" />
    </header>

    <main class="flex flex-col items-stretch justify-start gap-4 pt-4 w-full">
      <slot />
    </main>
  </section>
</template>

<style scoped>
section {
  background: linear-gradient(0deg, rgba(0, 27, 46, 0.35), rgba(0, 27, 46, 0.35)),
    linear-gradient(24.63deg, rgba(0, 12, 20, 0.2) 0%, rgba(0, 12, 20, 0) 49.92%),
    linear-gradient(204.53deg, rgba(249, 250, 251, 0.2) 0%, rgba(249, 250, 251, 0) 50.08%);
}
</style>
