<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col justify-end items-stretch md:justify-center z-0"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <p>
        {{ t("onboarding.step16.getWMATIC") }}
      </p>

      <p>
        {{ t("onboarding.step16.howToGetWMATIC") }}
      </p>

      <div class="p-2">
        <PaginatedOnboardingFooter continue-text-key="onboarding.step16.understood" />
      </div>
    </div>

    <div class="hidden md:block h-52 w-full">
      <!-- Spacer div to shift modal a bit above the center of the screen (where applied) -->
    </div>
  </div>
</template>

<style scoped></style>
