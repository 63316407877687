<script setup lang="ts">
import { CourtType } from "fungi-types";

const props = defineProps<{
  type: CourtType;
}>();
</script>

<template>
  <div class="court-type-icon">
    <img
      v-if="props.type === CourtType.MULTI"
      src="/images/multi_court.png"
      alt="Multi court icon"
    />
    <img
      v-else-if="props.type === CourtType.GRASS"
      src="/images/grass_court.png"
      alt="Grass court icon"
    />
    <img
      v-else-if="props.type === CourtType.HARD"
      src="/images/hard_court.png"
      alt="Hard court icon"
    />
    <img v-else src="/images/clay_court.png" alt="Clay court icon" />
  </div>
</template>

<style scoped></style>
