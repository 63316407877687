import { loadStripe, StripeEmbeddedCheckout } from "@stripe/stripe-js";
import { ref, Ref } from "vue";
import { makeApi } from "~/api";
import Currency from "~/common/enums/Currency";

const stripePublishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string;

if (!stripePublishableKey) {
  throw new Error("Stripe publishable key is not set");
}

const api = makeApi(`${import.meta.env.VITE_API_URL}/payments/stripe`);

import { defineStore } from "pinia";

export const useStripeStore = defineStore("stripe", () => {
  const stripeLoader = loadStripe(stripePublishableKey);
  const checkoutSessionInProgress = ref(false);
  const checkoutSession = ref<StripeEmbeddedCheckout | null>(null);

  async function fetchClientSecret(auctionCuid: string, amountInCents: number, currency: Currency) {
    const { data } = await api.post<{}, { data: { clientSecret: string } }>("/checkout", {
      currency,
      auctionId: auctionCuid,
      amount: amountInCents,
    });

    return data.clientSecret;
  }

  async function launchCheckoutSession(
    ref: Ref<HTMLElement>,
    auctionCuid: string,
    amountInCents: number,
    currency: Currency,
    successCallback?: () => unknown,
    errorCallback?: () => unknown
  ) {
    checkoutSessionInProgress.value = false;

    const stripe = await stripeLoader;
    if (!stripe) {
      console.error("Failed to load Stripe");
      return;
    }

    checkoutSession.value = await stripe.initEmbeddedCheckout({
      fetchClientSecret: async () => {
        try {
          const clientSecret = await fetchClientSecret(auctionCuid, amountInCents, currency);
          checkoutSessionInProgress.value = true;
          return clientSecret;
        } catch (error) {
          console.error("Something went wrong while fetching stripe client secret", error);

          checkoutSessionInProgress.value = false;

          checkoutSession.value?.destroy();

          errorCallback?.();

          throw error;
        }
      },
      onComplete: async () => {
        checkoutSession.value?.destroy();

        checkoutSessionInProgress.value = false;

        successCallback?.();

        checkoutSession.value = null;
      },
    });

    checkoutSession.value?.mount(ref.value);

    return checkoutSession;
  }

  function cancelCheckoutSession() {
    checkoutSession.value?.destroy();
    checkoutSession.value = null;
    checkoutSessionInProgress.value = false;
  }

  return {
    launchCheckoutSession,
    fetchClientSecret,
    checkoutSessionInProgress,
    checkoutSession,
    cancelCheckoutSession,
  };
});

export default function useStripe() {
  const stripeLoader = loadStripe(stripePublishableKey);
  const checkoutSessionInProgress = ref(false);
  const checkoutSession = ref<StripeEmbeddedCheckout | null>(null);

  async function fetchClientSecret(auctionCuid: string, amountInCents: number, currency: Currency) {
    const { data } = await api.post<{}, { data: { clientSecret: string } }>("/checkout", {
      currency,
      auctionId: auctionCuid,
      amount: amountInCents,
    });

    return data.clientSecret;
  }

  async function launchCheckoutSession(
    ref: Ref<HTMLElement>,
    auctionCuid: string,
    amountInCents: number,
    currency: Currency,
    successCallback?: () => unknown,
    errorCallback?: () => unknown
  ) {
    checkoutSessionInProgress.value = false;

    const stripe = await stripeLoader;
    if (!stripe) {
      console.error("Failed to load Stripe");
      return;
    }

    checkoutSession.value = await stripe.initEmbeddedCheckout({
      fetchClientSecret: async () => {
        try {
          const clientSecret = await fetchClientSecret(auctionCuid, amountInCents, currency);
          checkoutSessionInProgress.value = true;
          return clientSecret;
        } catch (error) {
          console.error("Something went wrong while fetching stripe client secret", error);

          checkoutSessionInProgress.value = false;

          checkoutSession.value?.destroy();

          errorCallback?.();

          throw error;
        }
      },
      onComplete: async () => {
        checkoutSession.value?.destroy();

        checkoutSessionInProgress.value = false;

        successCallback?.();

        checkoutSession.value = null;
      },
    });

    checkoutSession.value?.mount(ref.value);

    return checkoutSession;
  }

  function cancelCheckoutSession() {
    checkoutSession.value?.destroy();
    checkoutSession.value = null;
    checkoutSessionInProgress.value = false;
  }

  return {
    launchCheckoutSession,
    fetchClientSecret,
    checkoutSessionInProgress,
    checkoutSession,
    cancelCheckoutSession,
  };
}
