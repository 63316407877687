//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SecondaryExecutor
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x395562D4EF170cf9b23ccF53521700F77dd6dca4)
 */
export const secondaryExecutorAbi = [
  {
    type: "constructor",
    inputs: [
      { name: "_nftContractAddress", internalType: "address", type: "address" },
      { name: "_wmaticToken", internalType: "address", type: "address" },
      {
        name: "_seaportContractAddress",
        internalType: "address",
        type: "address",
      },
      { name: "_conduitAddress", internalType: "address", type: "address" },
      {
        name: "_fulfillerConduitKey",
        internalType: "bytes32",
        type: "bytes32",
      },
    ],
    stateMutability: "nonpayable",
  },
  { type: "error", inputs: [], name: "AccessControlBadConfirmation" },
  {
    type: "error",
    inputs: [
      { name: "account", internalType: "address", type: "address" },
      { name: "neededRole", internalType: "bytes32", type: "bytes32" },
    ],
    name: "AccessControlUnauthorizedAccount",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenId",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "buyerAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
    ],
    name: "Buy",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenId",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "owner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "Cancel",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "newAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "ConduitAddressUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "newFulfillerConduitKey",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
    ],
    name: "FulfillerConduitKeyUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "newAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "NFTContractAddressUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "status", internalType: "bool", type: "bool", indexed: true },
      {
        name: "newAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "PausedUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      {
        name: "previousAdminRole",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
      {
        name: "newAdminRole",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
    ],
    name: "RoleAdminChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "sender",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "RoleGranted",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "sender",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "RoleRevoked",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "newAddress",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "SeaportAddressUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "tokenId",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "buyerAddres",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "signature",
        internalType: "bytes",
        type: "bytes",
        indexed: true,
      },
    ],
    name: "SecondaryFulfilled",
  },
  {
    type: "function",
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "EXECUTOR_ROLE",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "spender", internalType: "address", type: "address" },
      { name: "amount", internalType: "uint256", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [
      {
        name: "order",
        internalType: "struct SeaportInterface.Order",
        type: "tuple",
        components: [
          {
            name: "parameters",
            internalType: "struct SeaportInterface.OrderParameters",
            type: "tuple",
            components: [
              { name: "offerer", internalType: "address", type: "address" },
              { name: "zone", internalType: "address", type: "address" },
              {
                name: "offer",
                internalType: "struct SeaportInterface.OfferItem[]",
                type: "tuple[]",
                components: [
                  {
                    name: "itemType",
                    internalType: "enum SeaportInterface.ItemType",
                    type: "uint8",
                  },
                  { name: "token", internalType: "address", type: "address" },
                  {
                    name: "identifierOrCriteria",
                    internalType: "uint256",
                    type: "uint256",
                  },
                  {
                    name: "startAmount",
                    internalType: "uint256",
                    type: "uint256",
                  },
                  {
                    name: "endAmount",
                    internalType: "uint256",
                    type: "uint256",
                  },
                ],
              },
              {
                name: "consideration",
                internalType: "struct SeaportInterface.ConsiderationItem[]",
                type: "tuple[]",
                components: [
                  {
                    name: "itemType",
                    internalType: "enum SeaportInterface.ItemType",
                    type: "uint8",
                  },
                  { name: "token", internalType: "address", type: "address" },
                  {
                    name: "identifierOrCriteria",
                    internalType: "uint256",
                    type: "uint256",
                  },
                  {
                    name: "startAmount",
                    internalType: "uint256",
                    type: "uint256",
                  },
                  {
                    name: "endAmount",
                    internalType: "uint256",
                    type: "uint256",
                  },
                  {
                    name: "recipient",
                    internalType: "address payable",
                    type: "address",
                  },
                ],
              },
              {
                name: "orderType",
                internalType: "enum SeaportInterface.OrderType",
                type: "uint8",
              },
              { name: "startTime", internalType: "uint256", type: "uint256" },
              { name: "endTime", internalType: "uint256", type: "uint256" },
              { name: "zoneHash", internalType: "bytes32", type: "bytes32" },
              { name: "salt", internalType: "uint256", type: "uint256" },
              { name: "conduitKey", internalType: "bytes32", type: "bytes32" },
              {
                name: "totalOriginalConsiderationItems",
                internalType: "uint256",
                type: "uint256",
              },
            ],
          },
          { name: "signature", internalType: "bytes", type: "bytes" },
        ],
      },
    ],
    name: "buy",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [
      {
        name: "orders",
        internalType: "struct SeaportInterface.OrderComponents[]",
        type: "tuple[]",
        components: [
          { name: "offerer", internalType: "address", type: "address" },
          { name: "zone", internalType: "address", type: "address" },
          {
            name: "offer",
            internalType: "struct SeaportInterface.OfferItem[]",
            type: "tuple[]",
            components: [
              {
                name: "itemType",
                internalType: "enum SeaportInterface.ItemType",
                type: "uint8",
              },
              { name: "token", internalType: "address", type: "address" },
              {
                name: "identifierOrCriteria",
                internalType: "uint256",
                type: "uint256",
              },
              { name: "startAmount", internalType: "uint256", type: "uint256" },
              { name: "endAmount", internalType: "uint256", type: "uint256" },
            ],
          },
          {
            name: "consideration",
            internalType: "struct SeaportInterface.ConsiderationItem[]",
            type: "tuple[]",
            components: [
              {
                name: "itemType",
                internalType: "enum SeaportInterface.ItemType",
                type: "uint8",
              },
              { name: "token", internalType: "address", type: "address" },
              {
                name: "identifierOrCriteria",
                internalType: "uint256",
                type: "uint256",
              },
              { name: "startAmount", internalType: "uint256", type: "uint256" },
              { name: "endAmount", internalType: "uint256", type: "uint256" },
              {
                name: "recipient",
                internalType: "address payable",
                type: "address",
              },
            ],
          },
          {
            name: "orderType",
            internalType: "enum SeaportInterface.OrderType",
            type: "uint8",
          },
          { name: "startTime", internalType: "uint256", type: "uint256" },
          { name: "endTime", internalType: "uint256", type: "uint256" },
          { name: "zoneHash", internalType: "bytes32", type: "bytes32" },
          { name: "salt", internalType: "uint256", type: "uint256" },
          { name: "conduitKey", internalType: "bytes32", type: "bytes32" },
          { name: "counter", internalType: "uint256", type: "uint256" },
        ],
      },
    ],
    name: "cancel",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "conduitAddress",
    outputs: [{ name: "", internalType: "address", type: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "fulfillerConduitKey",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [{ name: "role", internalType: "bytes32", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "maxConsiderationItems",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "nftContract",
    outputs: [{ name: "", internalType: "contract ERC721Interface", type: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "", internalType: "address", type: "address" },
      { name: "", internalType: "uint256", type: "uint256" },
      { name: "", internalType: "bytes", type: "bytes" },
    ],
    name: "onERC721Received",
    outputs: [{ name: "", internalType: "bytes4", type: "bytes4" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "paused",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "callerConfirmation", internalType: "address", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "seaportContract",
    outputs: [{ name: "", internalType: "contract SeaportInterface", type: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [
      { name: "operator", internalType: "address", type: "address" },
      { name: "status", internalType: "bool", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "newAddress", internalType: "address", type: "address" }],
    name: "setConduitAddress",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [
      {
        name: "newFulfillerConduitKey",
        internalType: "bytes32",
        type: "bytes32",
      },
    ],
    name: "setFulfillerConduitKey",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "_maxItems", internalType: "uint256", type: "uint256" }],
    name: "setMaxConsiderationItems",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "newAddress", internalType: "address", type: "address" }],
    name: "setNFTContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "newAddress", internalType: "address", type: "address" }],
    name: "setSeaportAddress",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [{ name: "interfaceId", internalType: "bytes4", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
    stateMutability: "view",
  },
  {
    type: "function",
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    inputs: [],
    name: "wmaticToken",
    outputs: [{ name: "", internalType: "contract WMATICInterface", type: "address" }],
    stateMutability: "view",
  },
] as const;

/**
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x395562D4EF170cf9b23ccF53521700F77dd6dca4)
 */
export const secondaryExecutorAddress = {
  80001: "0x944dEF00C182b07A4a8E5d3ABf25D27F6df3De1C",
} as const;

/**
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x395562D4EF170cf9b23ccF53521700F77dd6dca4)
 */
export const secondaryExecutorConfig = {
  address: secondaryExecutorAddress,
  abi: secondaryExecutorAbi,
} as const;
