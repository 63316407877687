<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div
    class="xl:max-w-[50%] md:max-w-[50%] pb-20 md:pb-0 flex flex-col justify-end items-stretch md:justify-center z-0"
  >
    <div class="hidden md:block h-[33vh] w-full score-card-spacer">
      <!-- Spacer div to shift modal a bit below the center of the screen (where applied) -->
    </div>

    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4 pointer-events-auto"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <p>
        {{ t("onboarding.step15.checkRanking") }}
      </p>

      <div class="p-2">
        <PaginatedOnboardingFooter continue-text-key="onboarding.step15.ok" />
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (max-height: 900px) {
  .score-card-spacer {
    height: 60vh;
  }
}
</style>
