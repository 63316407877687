<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  min: number;
  max: number;
  value: number;
  showPercentage?: boolean;
  color?: string;
  percentageClass?: string;
}>();

const progress = computed(() => Math.round((100 * props.value) / (props.max - props.min)));
</script>

<template>
  <div class="horizontal-gauge flex items-stretch justify-between gap-2">
    <svg viewBox="0 0 11 2">
      <line
        x1="1"
        x2="10"
        y1="1"
        y2="1"
        stroke-linecap="round"
        class="text-black"
        stroke="currentColor"
        stroke-width="1"
      />
      <line
        x1="1"
        :x2="progress / 10"
        y1="1"
        y2="1"
        stroke-linecap="round"
        :stroke="props.color ?? 'currentColor'"
        stroke-width="1"
      />
    </svg>

    <span v-if="props.showPercentage" :class="['font-display', percentageClass].filter(Boolean)">
      {{ progress }}&nbsp;%
    </span>
  </div>
</template>

<style scoped></style>
