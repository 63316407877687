<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col items-stretch sm:max-w-xl w-full z-0 justify-end md:justify-center"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <div class="flex flex-col gap-4">
        <p>
          <i18n-t keypath="onboarding.step2.youNeed4Skills">
            <template #fourBasicSkills>
              <span class="font-semibold"
                >{{ t("onboarding.step2.fourBasicSkills_bold") }}&nbsp;</span
              >
              <span>{{ t("onboarding.step2.fourBasicSkills") }}</span>
            </template>
          </i18n-t>
        </p>

        <ul class="list-disc list-outside ml-[1em] [&_li]:pb-2">
          <li>
            <i18n-t keypath="onboarding.step2.primaryMarketPlaceOption">
              <template #primaryMarketPlace>
                <span class="font-semibold">{{ t("onboarding.step2.primaryMarketPlace") }}</span>
              </template>
            </i18n-t>
          </li>
          <li>
            <i18n-t keypath="onboarding.step2.secondaryMarketPlaceOption">
              <template #secondaryMarketPlace>
                <span class="font-semibold">{{ t("onboarding.step2.secondaryMarketPlace") }}</span>
              </template>
            </i18n-t>
          </li>
        </ul>
      </div>

      <div class="p-2">
        <PaginatedOnboardingFooter continue-text-key="onboarding.step2.ok" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
