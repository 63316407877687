<script setup lang="ts">
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import { useI18n } from "vue-i18n";
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";

const { t } = useI18n();

const onboardingStore = useOnboarding();
const { rarityDemoCards } = onboardingStore;
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col justify-end items-stretch sm:justify-center z-0"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <div
        class="grow flex flex-col items-stretch md:flex-row md:justify-around gap-4 md:gap-10 overflow-y-auto"
      >
        <CardsFader :cards="rarityDemoCards" :timing="2_500" />

        <div class="flex flex-col justify-between items-stretch gap-2">
          <div class="grow flex flex-col justify-center items-stretch gap-2">
            <p class="font-semibold">
              {{ t("onboarding.step4.skillRarity") }}
            </p>

            <ul class="list-disc list-outside ml-[2em] [&_li}:pb-4">
              <li>{{ t("onboarding.step4.silver") }}</li>
              <li>{{ t("onboarding.step4.gold") }}</li>
              <li>{{ t("onboarding.step4.platinum") }}</li>
            </ul>

            <p class="py-2">
              {{ t("onboarding.step4.rarityImpactOnScore") }}
            </p>
          </div>

          <PaginatedOnboardingFooter
            class="hidden md:flex p-2"
            continue-text-key="onboarding.step4.ok"
          />
        </div>
      </div>

      <PaginatedOnboardingFooter class="md:hidden p-2" continue-text-key="onboarding.step4.ok" />
    </div>
  </div>
</template>

<style scoped></style>
