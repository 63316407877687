<script setup lang="ts">
import { ChevronsUpDownIcon, ChevronDownIcon, ChevronUpIcon } from "lucide-vue-next";
const props = defineProps<{
  value: "asc" | "desc" | undefined;
}>();

const emit = defineEmits<{
  (event: "click"): void;
}>();
</script>

<template>
  <button class="sort-indicator" @click.stop="emit('click')">
    <ChevronsUpDownIcon :size="14" v-if="props.value === undefined" />
    <ChevronUpIcon :size="14" v-else-if="props.value === 'desc'" />
    <ChevronDownIcon :size="14" v-else />
  </button>
</template>

<style scoped></style>
