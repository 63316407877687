<script setup lang="ts">
import { MarketType } from "fungi-types";
import { useI18n } from "vue-i18n";
import { CurrentlyAvailableCardAuction } from "~/components/card-details/use-card-details";

const props = defineProps<{
  card: CurrentlyAvailableCardAuction;
}>();

const emits = defineEmits<{
  (event: "click"): void;
}>();

const { t } = useI18n();
</script>

<template>
  <button
    class="capitalize bg-grey-30/20 rounded-md px-2 text-xs py-1 transition-colors duration-200 hover:bg-primary"
    @click.stop="emits('click')"
  >
    {{
      t(
        card.marketType === MarketType.PRIMARY
          ? "pages.marketplace.NewCards.bid-now"
          : "pages.marketplace.trade.buy",
        card.marketType === MarketType.PRIMARY ? "Place bid" : "Buy"
      )
    }}
  </button>
</template>
