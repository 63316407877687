"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationType = exports.EmailTemplate = exports.PubSubTopic = void 0;
var PubSubTopic;
(function (PubSubTopic) {
    PubSubTopic["NOTIFICATION_INPUTS"] = "notifications-input";
})(PubSubTopic || (exports.PubSubTopic = PubSubTopic = {}));
var EmailTemplate;
(function (EmailTemplate) {
    EmailTemplate["OUTBID"] = "outbid";
    EmailTemplate["AUCTION_WINNER"] = "winner";
    EmailTemplate["WELCOME"] = "welcome";
})(EmailTemplate || (exports.EmailTemplate = EmailTemplate = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["EMAIL"] = "EMAIL";
    NotificationType["EMAIL_BROADCAST"] = "EMAIL_BROADCAST";
    NotificationType["IN_APP"] = "IN_APP";
    NotificationType["IN_APP_BROADCAST"] = "IN_APP_BROADCAST";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
