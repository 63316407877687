<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import NftSkill from "~/components/marketplace/NftSkill.vue";

const { t } = useI18n();

const onboardingStore = useOnboarding();
const { demoCard } = onboardingStore;
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col justify-end items-stretch md:justify-center z-0"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <div
        class="grow flex flex-col items-center md:flex-row md:justify-around gap-4 md:gap-10 overflow-y-auto"
      >
        <NftSkill :nft="demoCard">
          <template #footer-data>
            <div class="w-full flex items-center justify-between">
              <div
                :class="`bg-skill-${demoCard.skill}`"
                class="rounded-br-lg px-3 small-text md:text-sm score-container"
              >
                <p class="font-sans font-semibold">{{ demoCard.score }}</p>
              </div>
            </div>
          </template>
        </NftSkill>

        <div class="md:h-full flex flex-col justify-between items-stretch gap-2">
          <div class="grow flex flex-col justify-center gap-2">
            <p class="whitespace-pre-line">
              {{ t("onboarding.step8.congrats") }}
            </p>

            <p>
              {{ t("onboarding.step8.howToRegister") }}
            </p>
          </div>

          <div class="my-4 md:my-0 lg:text-sm xl:text-base">
            <PaginatedOnboardingFooter continue-text-key="onboarding.step8.goToRegistration" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
