<script setup lang="ts">
import { inject } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import {
  CurrentlyAvailableCardAuction,
  useCardDetails,
} from "~/components/card-details/use-card-details";
import useDynamicSort from "~/composables/useDynamicSort";
import { NftSkill, Skill } from "fungi-types";
import BidOrBuyButton from "~/components/card-details/AvailableCards/BidOrBuyButton.vue";

const emits = defineEmits<{
  (event: "bid-or-buy", token: CurrentlyAvailableCardAuction): void;
}>();

const skillsColors = inject<Record<Skill, string>>("skills-colors")!;

const { t } = useI18n();

const cardDetails = useCardDetails();
const { cardSales } = storeToRefs(cardDetails);
const availableCards = useDynamicSort(cardSales);
</script>

<template>
  <table class="table-auto border-separate border-spacing-0">
    <thead class="bg-black align-middle [&_th]:font-normal text-sm">
      <tr>
        <th
          class="text-start py-3 pl-4 rounded-tl-lg border border-r-0 border-grey-30 border-opacity-20 align-middle"
        >
          <div
            @click="availableCards.changeFilter('owner')"
            class="flex flex-row items-center gap-2 cursor-pointer"
          >
            <span>
              {{ t("cardDetails.availableCards.table.owner") }}
            </span>
            <SortIndicator :value="availableCards.filters.value.owner" />
          </div>
        </th>

        <th class="text-start border-t border-b border-grey-30 border-opacity-20 pl-4">
          <div
            @click="availableCards.changeFilter('level')"
            class="flex flex-row items-center justify-end gap-2 cursor-pointer"
          >
            <SortIndicator :value="availableCards.filters.value.level" />
            <span>{{ t("cardDetails.availableCards.table.level") }}</span>
          </div>
        </th>

        <th class="text-start border-t border-b border-grey-30 border-opacity-20 px-4">
          <div class="flex flex-row items-center justify-center gap-2">
            <span>{{ t("cardDetails.availableCards.table.surface") }}</span>
          </div>
        </th>

        <th
          class="text-start border-t border-b border-grey-30 border-opacity-20"
          v-if="availableCards.sorted.value.some((auction) => auction.wear != null)"
        >
          <div
            @click="availableCards.changeFilter('wear')"
            class="flex flex-row items-center justify-end gap-2 cursor-pointer"
          >
            <span>{{ t("cardDetails.availableCards.table.wear") }}</span>
            <SortIndicator :value="availableCards.filters.value.wear" />
          </div>
        </th>

        <th class="text-start border-t border-b border-grey-30 border-opacity-20">
          <div
            @click="availableCards.changeFilter('maticPrice')"
            class="flex flex-row items-center justify-end gap-2 cursor-pointer"
          >
            <SortIndicator :value="availableCards.filters.value.maticPrice" />
            <span>{{ t("cardDetails.availableCards.table.sellingPrice") }}</span>
          </div>
        </th>

        <th class="text-start border-t border-b border-grey-30 border-opacity-20 pl-8">
          <div
            @click="availableCards.changeFilter('endedAt')"
            class="flex flex-row justify-start items-center gap-2 cursor-pointer"
          >
            <span>
              {{ t("cardDetails.availableCards.table.remainingTime") }}
            </span>
            <SortIndicator :value="availableCards.filters.value.endedAt" />
          </div>
        </th>

        <th
          class="text-start pr-4 rounded-tr-lg border border-l-0 border-grey-30 border-opacity-20"
        >
          <div class="flex flex-row justify-center items-center gap-2"></div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="availableCard in availableCards.sorted.value"
        :key="availableCard.cuid"
        class="text-sm"
      >
        <td
          class="py-3 pl-4 align-middle border border-t-0 border-r-0 border-grey-30 border-opacity-20"
        >
          {{ availableCard.owner }}
        </td>

        <td class="py-3 align-middle border-b border-grey-30 border-opacity-20">
          <div class="flex flex-row items-center justify-end">
            {{ availableCard.level }}
          </div>
        </td>

        <td class="py-3 align-middle border-b border-grey-30 border-opacity-20">
          <div class="flex flex-row items-center justify-center">
            <CourtTypeIcon :type="(availableCard.card as NftSkill).courtType" class="h-6 w-6" />
          </div>
        </td>

        <td
          class="py-3 align-middle border-b border-grey-30 border-opacity-20"
          v-if="availableCards.sorted.value.some((auction) => auction.wear != null)"
        >
          <div class="flex flex-row items-center justify-end" v-if="availableCard.wear != null">
            <HorizontalGauge
              class="h-6"
              :value="availableCard.wear"
              :min="0"
              :max="100"
              :color="skillsColors[cardDetails.skill!]"
              show-percentage
            />
          </div>
        </td>

        <td class="py-3 align-middle border-b border-grey-30 border-opacity-20">
          <div class="flex flex-row justify-end items-center gap-2">
            <span>
              {{ availableCard.maticPrice?.toFixed(2) ?? "N/A" }}
            </span>
            <IconFgcWMatic class="w-4" />
          </div>
        </td>

        <td class="py-3 align-middle border-b border-grey-30 border-opacity-20 pl-8">
          <div class="flex justify-start items-center">
            <OvTicker
              :cuid="availableCard.cuid"
              :end-date="availableCard.endedAt"
              :status="availableCard.status"
            />
          </div>
        </td>

        <td
          class="py-3 pr-4 align-middle border border-t-0 border-l-0 border-grey-30 border-opacity-20"
        >
          <div class="flex flex-row items-center justify-start pl-2">
            <BidOrBuyButton :card="availableCard" @click="emits('bid-or-buy', availableCard)" />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped></style>
