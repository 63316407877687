import { computed, Ref, ref, UnwrapRef } from "vue";
import { compareAsc } from "date-fns";

type TFilter<T> = {
  key: keyof T;
  filter: "asc" | "desc" | undefined;
};

export default function useDynamicSort<T>(data: T[] | Ref<T[]>, defaultSort?: TFilter<T>[]) {
  const filters = ref<TFilter<T>[]>(defaultSort ?? []);

  const sorted = computed<T[]>(() => {
    return [...(Array.isArray(data) ? data : data.value)].sort((a, b) => {
      for (const filter of filters.value) {
        const key = filter.key;
        const order = filter.filter;

        if (order === undefined) continue;

        const aVal = a[key as keyof T];
        const bVal = b[key as keyof T];

        if (aVal === bVal) continue;

        if (aVal instanceof Date && bVal instanceof Date) {
          const compareDateResult = compareAsc(aVal, bVal);

          if (compareDateResult === 0) continue;

          return order === "asc" ? compareDateResult : -compareDateResult;
        }

        const compareResult = aVal > bVal ? 1 : -1;
        return order === "asc" ? compareResult : -compareResult;
      }
      return 0;
    });
  });

  function changeFilter(key: keyof T) {
    const filterIdx = filters.value.findIndex((filter) => filter.key === key);

    if (filterIdx === -1) {
      filters.value.push({
        key: key as UnwrapRef<keyof T>,
        filter: "desc",
      });
    } else {
      const { filter } = filters.value.at(filterIdx)!;

      if (filter === "asc") {
        filters.value.at(filterIdx)!.filter = undefined;
      } else if (filter === "desc") {
        filters.value.at(filterIdx)!.filter = "asc";
      } else {
        filters.value.at(filterIdx)!.filter = "desc";
      }
    }
  }

  const condensedFilters = computed<Record<keyof T, TFilter<T>["filter"]>>(() => {
    return filters.value.reduce(
      (acc, { key, filter }) => {
        return { ...acc, [key as keyof T]: filter };
      },
      {} as Record<keyof T, TFilter<T>["filter"]>
    );
  });

  return { sorted, changeFilter, filters: condensedFilters };
}
