<script setup lang="ts">
import { computed } from "vue";

import MarketPlaceHeader from "./HeaderMarketPlace.vue";
import Locker from "./HeaderLocker.vue";
import { useRoute } from "vue-router";
const route = useRoute();

const head = computed(() => {
  switch (route.name) {
    case "Trade":
      return MarketPlaceHeader;
    case "NewCards":
      return MarketPlaceHeader;
    case "MyCards":
      return Locker;
    case "Transactions":
      return Locker;
    case "Listed":
      return Locker;
    case "MyEquipments":
      return Locker;
    default:
      return null;
  }
});
</script>

<template>
  <component v-if="head" :is="head" />
</template>
