interface LevelInfo {
  currentLevel: number;
  currentLevelXP: number;
  xpToNextLevel: number;
  xpRemaining: number;
  bonus: number;
}

interface LevelRequirement {
  xpThreshold: number;
  level: number;
  bonus: number;
}

const LEVEL_REQUIREMENTS: LevelRequirement[] = [
  { xpThreshold: 0, level: 0, bonus: 0 },
  { xpThreshold: 50, level: 1, bonus: 0.5 },
  { xpThreshold: 100, level: 2, bonus: 1 },
  { xpThreshold: 150, level: 3, bonus: 1.5 },
  { xpThreshold: 250, level: 4, bonus: 2 },
  { xpThreshold: 400, level: 5, bonus: 2.5 },
  { xpThreshold: 600, level: 6, bonus: 3 },
  { xpThreshold: 800, level: 7, bonus: 3.5 },
  { xpThreshold: 1000, level: 8, bonus: 4 },
  { xpThreshold: 1200, level: 9, bonus: 4.5 },
  { xpThreshold: 1500, level: 10, bonus: 5 },
  { xpThreshold: 1745, level: 11, bonus: 5.5 },
  { xpThreshold: 1990, level: 12, bonus: 6 },
  { xpThreshold: 2235, level: 13, bonus: 6.5 },
  { xpThreshold: 2480, level: 14, bonus: 7.0 },
  { xpThreshold: 2725, level: 15, bonus: 7.5 },
  { xpThreshold: 2970, level: 16, bonus: 8 },
  { xpThreshold: 3215, level: 17, bonus: 8.5 },
  { xpThreshold: 3460, level: 18, bonus: 9 },
  { xpThreshold: 3705, level: 19, bonus: 9.5 },
  { xpThreshold: 3950, level: 20, bonus: 10 },
];

const getCardLevel = (totalXP: number): LevelInfo => {
  // Find the current level based on XP
  let currentLevel = 0;
  for (let i = LEVEL_REQUIREMENTS.length - 1; i >= 0; i--) {
    if (totalXP >= LEVEL_REQUIREMENTS[i].xpThreshold) {
      currentLevel = i;
      break;
    }
  }

  // If no level found, return first level info
  if (currentLevel === 0) {
    return {
      currentLevel: LEVEL_REQUIREMENTS[0].level,
      currentLevelXP: 0,
      xpToNextLevel: LEVEL_REQUIREMENTS[0].xpThreshold,
      xpRemaining: LEVEL_REQUIREMENTS[0].xpThreshold,
      bonus: LEVEL_REQUIREMENTS[0].bonus,
    };
  }

  // If at max level
  if (currentLevel === LEVEL_REQUIREMENTS.length - 1) {
    return {
      currentLevel,
      currentLevelXP: LEVEL_REQUIREMENTS[currentLevel].xpThreshold,
      xpToNextLevel: 0,
      xpRemaining: 0,
      bonus: LEVEL_REQUIREMENTS[currentLevel].bonus,
    };
  }

  // Calculate current level XP and remaining XP
  const currentLevelXP = totalXP - LEVEL_REQUIREMENTS[currentLevel].xpThreshold;
  const nextLevelXP = LEVEL_REQUIREMENTS[currentLevel + 1].xpThreshold;
  const xpToNextLevel =
    nextLevelXP - LEVEL_REQUIREMENTS[currentLevel].xpThreshold;
  const xpRemaining = xpToNextLevel - currentLevelXP;
  const bonus = LEVEL_REQUIREMENTS[currentLevel].bonus;

  return {
    currentLevel: currentLevel, // +1 because levels start at 1, not 0
    currentLevelXP,
    xpToNextLevel,
    xpRemaining,
    bonus,
  };
};

const getXpRangeFromLevel = (level: number): [number, number] => {
  if (level <= 0) {
    return [0, 0];
  }

  const currentLevel = LEVEL_REQUIREMENTS[level - 1];
  const nextLevel = LEVEL_REQUIREMENTS[level];

  return [currentLevel.xpThreshold, nextLevel.xpThreshold];
};
export { getCardLevel, getXpRangeFromLevel };
