<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { CircleUserRound, DollarSignIcon } from "lucide-vue-next";
import { GameweekStatus, RegistrationStatus, Scarcity } from "fungi-types";
import genderMale from "~icons/fg/gender-male";

const { t } = useI18n();

const displayIdx = ref(0);
onMounted(() => {
  const timeBetweenInfos = 1_000;
  function showNext() {
    displayIdx.value++;

    if (displayIdx.value < 6) {
      setTimeout(showNext, timeBetweenInfos);
    }
  }

  setTimeout(showNext, 1_000);
});

const demoSeason = {
  name: "World Tour Men Gold",
  competition: {
    gender: "male",
    scarcity: Scarcity.GOLD,
  },
  user_registration: {
    status: RegistrationStatus.Draft,
  },
  registrations_count: 30,
  prize_money: 700,
  surface: "hard_outdoor",
};
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col justify-end items-stretch md:justify-center z-[60]"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4 z-[60]"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <div class="grow flex flex-col items-stretch md:flex-row gap-4 md:gap-8 overflow-y-auto">
        <div class="grow hover:!cursor-default flex flex-col items-stretch justify-center">
          <CompetitionCard
            :season="demoSeason"
            :gameweek-status="GameweekStatus.Upcoming"
            :onboarding-step="displayIdx"
            onboarding
            class="max-h-80"
          />
        </div>

        <div class="min-w-[55%] flex flex-col items-stretch gap-4 py-3">
          <div class="grow flex flex-col items-stretch justify-between gap-4">
            <p class="font-semibold">
              {{ t("onboarding.step9.playTournamentsAndWinPrizes") }}
            </p>

            <div class="flex flex-col items-stretch gap-3">
              <div
                :class="`flex flex-row justify-start items-center gap-2 transition-opacity duration-500 ${displayIdx < 1 ? 'opacity-0' : 'opacity-100'}`"
              >
                <div class="flex-shrink-0 w-6 h-4 flex items-center justify-start">
                  <icon-fg-cards />
                </div>
                <p class="">
                  {{ t("onboarding.step9.skillRarity") }}
                </p>
              </div>

              <div
                :class="`flex flex-row justify-start items-center gap-2 transition-opacity duration-500 ${displayIdx < 2 ? 'opacity-0' : 'opacity-100'}`"
              >
                <div class="flex-shrink-0 w-6 h-4 flex items-center justify-start">
                  <genderMale class="fill-white h-4" />
                </div>
                <p class="">
                  {{ t("onboarding.step9.playerGender") }}
                </p>
              </div>
            </div>

            <p
              :class="`font-semibold transition-opacity duration-500 ${displayIdx < 3 ? 'opacity-0' : 'opacity-100'}`"
            >
              {{ t("onboarding.step9.otherInfo") }}
            </p>

            <div class="flex flex-col items-stretch gap-3">
              <div
                :class="`flex flex-row justify-start items-center gap-2 transition-opacity duration-500 ${displayIdx < 4 ? 'opacity-0' : 'opacity-100'}`"
              >
                <div class="flex-shrink-0 w-6 h-4 flex items-center justify-start">
                  <CircleUserRound class="h-4" />
                </div>
                <p class="">
                  {{ t("onboarding.step9.registeredPlayers") }}
                </p>
              </div>

              <div
                :class="`flex flex-row justify-start items-center gap-2 transition-opacity duration-500 ${displayIdx < 5 ? 'opacity-0' : 'opacity-100'}`"
              >
                <div class="flex-shrink-0 w-6 h-4 flex items-center justify-start">
                  <DollarSignIcon class="h-4" />
                </div>
                <p class="">
                  {{ t("onboarding.step9.prizePool") }}
                </p>
              </div>
            </div>
          </div>

          <div class="hidden md:block md:my-0 lg:text-sm">
            <PaginatedOnboardingFooter continue-text-key="onboarding.step9.understood" />
          </div>
        </div>
      </div>

      <div class="p-2 md:hidden">
        <PaginatedOnboardingFooter continue-text-key="onboarding.step9.understood" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
