<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import NftSkill from "~/components/marketplace/NftSkill.vue";
import { onMounted, ref } from "vue";
import { Skill } from "fungi-types";

const { t } = useI18n();

const onboardingStore = useOnboarding();
const { demoCard } = onboardingStore;

const card = {
  ...demoCard,
  skill: Skill.SERVICE,
  image: "https://nft.fungiball.io/skills/10066.png",
};

const displayIdx = ref(0);

onMounted(() => {
  const timeBetweenInfos = 300;
  function showNext() {
    displayIdx.value++;

    if (displayIdx.value < 3) {
      setTimeout(showNext, timeBetweenInfos);
    }
  }

  setTimeout(showNext, 1_000);
});

// TODO: animation on cards
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col justify-end items-stretch md:justify-center z-0"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <div
        class="grow flex flex-col items-stretch md:flex-row md:justify-around gap-4 md:gap-10 overflow-y-auto"
      >
        <NftSkill class="self-center" :nft="card">
          <template #footer-data>
            <div class="w-full flex items-center justify-between">
              <div
                :class="`bg-skill-${card.skill}`"
                class="rounded-br-lg px-3 small-text md:text-sm score-container"
              >
                <p class="font-sans font-semibold">{{ card.score }}</p>
              </div>
            </div>
          </template>
        </NftSkill>

        <div class="flex flex-col justify-between items-stretch gap-2">
          <div class="grow flex flex-col justify-center items-stretch gap-6">
            <p class="font-semibold">
              {{ t("onboarding.step5.threeImportantInfo") }}
            </p>

            <div
              class="flex flex-row justify-start items-center gap-2 transition-opacity duration-500"
              :class="displayIdx >= 1 ? 'opacity-100' : 'opacity-0'"
            >
              <div class="flex-shrink-0 w-14 h-4 flex items-center justify-center">
                <img src="/images/level.png" alt="Card level" style="width: 100%" />
              </div>
              <p class="">
                {{ t("onboarding.step5.cardLevel") }}
              </p>
            </div>

            <div
              class="flex flex-row justify-start items-center gap-2 transition-opacity duration-500"
              :class="displayIdx >= 2 ? 'opacity-100' : 'opacity-0'"
            >
              <div class="flex-shrink-0 w-14 h-4 flex items-center justify-center">
                <img src="/images/hard_court.png" alt="Court type" style="height: 100%" />
              </div>
              <p class="">
                {{ t("onboarding.step5.courtType") }}
              </p>
            </div>

            <div
              class="flex flex-row justify-start items-center gap-2 transition-opacity duration-500 opacity-0"
              :class="displayIdx >= 3 ? 'opacity-100' : 'opacity-0'"
            >
              <div class="flex-shrink-0 w-14 h-4 flex items-center justify-center">
                <img src="/images/card-number.png" alt="Card number" style="width: 100%" />
              </div>
              <p class="">
                {{ t("onboarding.step5.cardNumber") }}
              </p>
            </div>
          </div>

          <div class="hidden md:block p-2">
            <PaginatedOnboardingFooter
              continue-text-key="onboarding.step5.getACard"
              :ok-button-disabled="displayIdx < 3"
            />
          </div>
        </div>
      </div>
      <div class="md:hidden p-2">
        <PaginatedOnboardingFooter
          continue-text-key="onboarding.step5.getACard"
          :ok-button-disabled="displayIdx < 3"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}
</style>
