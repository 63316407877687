<script setup lang="ts">
import { Skill } from "fungi-types";

const props = defineProps<{
  skill: Skill;
}>();
</script>

<template>
  <IconFgReturn v-if="props.skill === Skill.RETURN" />
  <IconFgPower v-else-if="props.skill === Skill.POWER" />
  <IconFgService v-else-if="props.skill === Skill.SERVICE" />
  <IconFgMental v-else-if="props.skill === Skill.MENTAL" />
</template>

<style scoped></style>
