import { Transak } from "@transak/transak-sdk";
import { useStoreUserV2 } from "@/stores/storeUserV2";
import { isProd, transakApiKey } from "@/application/config";
import { pinia } from "~/main";

export const useTransak = () => {
  const showOverlay = (mode: "desktop" | "mobile") => {
    const storeUser = useStoreUserV2(pinia);

    const transak = new Transak({
      apiKey: transakApiKey,
      environment: isProd ? Transak.ENVIRONMENTS.PRODUCTION : Transak.ENVIRONMENTS.STAGING,
      defaultCryptoCurrency: "POL",
      defaultFiatCurrency: "EUR",
      defaultPaymentMethod: "credit_debit_card",
      defaultFiatAmount: 10,
      cryptoCurrencyList: "POL",
      walletAddress: storeUser.wallet.currentAccount!,
      email: storeUser.email,
      widgetHeight: mode === "desktop" ? "625px" : "100%",
      widgetWidth: mode === "desktop" ? "500px" : "100%",
    });

    transak.init();

    Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      storeUser.refreshBalance();
      transak.close();
    });

    Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, (data) => {
      transak.close();
    });
  };

  return {
    showOverlay,
  };
};
