<script setup lang="ts">
import { Skill } from "fungi-types";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  activeSkill: Skill;
  skillScores: Record<Skill, number>;
}>();

const emits = defineEmits<{
  (event: "change", skill: Skill): void;
}>();

const { t } = useI18n();

const skills = [Skill.POWER, Skill.SERVICE, Skill.RETURN, Skill.MENTAL];

function changeSkill(newSkill: Skill) {
  if (newSkill === props.activeSkill) return;

  emits("change", newSkill);
}
</script>

<template>
  <form @submit.prevent class="flex flex-row items-stretch text-sm">
    <button
      v-for="skill in skills"
      :key="skill"
      @click.stop.prevent="changeSkill(skill)"
      :class="{
        'grow flex flex-row items-center justify-center gap-2 pb-2 font-semibold border-b border-grey-30 hover:border-b-2 transition-all duration-100': true,
        'border-skill-mental': skill === Skill.MENTAL && props.activeSkill === skill,
        'border-skill-service': skill === Skill.SERVICE && props.activeSkill === skill,
        'border-skill-power': skill === Skill.POWER && props.activeSkill === skill,
        'border-skill-return': skill === Skill.RETURN && props.activeSkill === skill,
        'hover:border-skill-mental': skill === Skill.MENTAL,
        'hover:border-skill-service': skill === Skill.SERVICE,
        'hover:border-skill-power': skill === Skill.POWER,
        'hover:border-skill-return': skill === Skill.RETURN,
        'border-b-2 border-opacity-100': props.activeSkill === skill,
        'border-opacity-30 hover:border-opacity-80': props.activeSkill !== skill,
      }"
    >
      <span
        class="align-middle px-3 py-0.5 rounded-tl-md rounded-br-md"
        :class="`bg-skill-${skill}`"
      >
        {{ props.skillScores[skill] }}
      </span>
      <span class="uppercase hidden lg:block">{{ t(`skills.${skill}`) }}</span>
      <span class="lg:hidden">
        <SkillIcon :skill="skill" />
      </span>
    </button>
  </form>
</template>
