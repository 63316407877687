<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";

const { t } = useI18n();

const onboardingStore = useOnboarding();

useStoreMarketplace().selectedItem = onboardingStore.demoAuction;
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col justify-end items-stretch md:justify-center z-0"
  >
    <ModalBid class="md:max-w-none" onboarding />

    <div
      class="mt-2 bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic force-display />

      <p>
        {{ t("onboarding.step7.try") }}
      </p>

      <div class="p-2">
        <PaginatedOnboardingFooter hide-ok-button />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
