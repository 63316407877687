"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportedLanguages = exports.EPreferredLanguage = void 0;
var EPreferredLanguage;
(function (EPreferredLanguage) {
    EPreferredLanguage["EN"] = "en";
    EPreferredLanguage["FR"] = "fr";
})(EPreferredLanguage || (exports.EPreferredLanguage = EPreferredLanguage = {}));
exports.supportedLanguages = [
    EPreferredLanguage.EN,
    EPreferredLanguage.FR,
];
