import { CardSkills, CardRarity } from "~/application/enums/Card.enum";

export const stringToHslColor = (str: string, s = 90, l = 50): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) hash = str.charCodeAt(i) + ((hash << 5) - hash);

  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export const scarcityStyle = (rarity: string) => {
  const style = {
    backgroundColor: "#000000",
    color: "#ffffff",
  };
  switch (rarity.toUpperCase()) {
    case CardRarity.GOLD:
      style.backgroundColor = "#ffd700";
      style.color = "#000000";
      break;
    case CardRarity.SILVER:
      style.backgroundColor = "#c0c0c0";
      style.color = "#555555";
      break;
    case CardRarity.PLATINUM:
      style.backgroundColor = "#010110";
      style.color = "#fefefe";
      break;

    default:
      break;
  }
  return style;
};

export const typeStyle = (skill: string) => {
  const style = {
    backgroundColor: "#000000",
    color: "#ffffff",
  };
  switch (skill.toUpperCase()) {
    case CardSkills.RETURN:
      style.backgroundColor = "#3af";
      style.color = "#c6ffff";
      break;
    case CardSkills.MENTAL:
      style.backgroundColor = "#53aa33";
      style.color = "#eec0ff";
      break;
    case CardSkills.POWER:
      style.backgroundColor = "#e39e12";
      style.color = "#a9ffb5";
      break;
    case CardSkills.SERVICE:
      style.backgroundColor = "#a42a27";
      style.color = "#ffa0c6";
      break;

    default:
      break;
  }
  return style;
};

export const scoreColor = (score: number | null) => {
  if (score === null) {
    return "#CCD0D6";
  } else if (score >= 71) {
    return "#40AA48";
  } else if (score >= 51 && score <= 70) {
    return "#32AECD";
  } else if (score >= 31 && score <= 50) {
    return "#F2BD0D";
  } else if (score >= 11 && score <= 30) {
    return "#E38112";
  } else if (score < 11) {
    return "#BD270E";
  }
};
