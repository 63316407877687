<script setup lang="ts">
import { onMounted, ref, toRefs, watch } from "vue";
import Chart, { ScriptableContext } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const props = defineProps<{
  data: (number | null)[];
  labels: string[];
  ticks?: number;
  min?: number;
  max?: number;
  gradientColor0?: string;
  gradientColor1?: string;
}>();

const { data, labels, gradientColor0, gradientColor1 } = toRefs(props);

let chart: Chart;
let legendChart: Chart;
const legendCanvas = ref<HTMLCanvasElement>();
const chartCanvas = ref<HTMLCanvasElement>();

const width = ref<number>();
const height = ref<number>();
const barGradient = ref<CanvasGradient>();

const colWidthPx = 25;

const needsGradientRefresh = ref(false);

watch([gradientColor0, gradientColor1], () => {
  needsGradientRefresh.value = true;
});

function getGradient(
  ctx: CanvasRenderingContext2D,
  chartArea: { left: number; right: number; top: number; bottom: number }
) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  if (
    needsGradientRefresh.value ||
    !barGradient.value ||
    width.value !== chartWidth ||
    height.value !== chartHeight
  ) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width.value = chartWidth;

    height.value = chartHeight;
    barGradient.value = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    barGradient.value!.addColorStop(0, props.gradientColor0 ?? "#33AAFF00");
    barGradient.value!.addColorStop(1, props.gradientColor1 ?? "#33AAFFCC");

    needsGradientRefresh.value = false;
  }

  return barGradient.value;
}

const tickSize = (() => {
  if ((props.ticks ?? 0) <= 0) return undefined;

  if (props.min != null && props.max != null && props.min < props.max) {
    return (props.max - props.min) / props.ticks!;
  }

  return (Math.max(...props.data) - Math.min(...props.data)) / props.ticks!;
})();

onMounted(() => {
  chart = new Chart(chartCanvas.value!, {
    type: "bar",
    plugins: [ChartDataLabels],
    data: {
      datasets: [
        {
          label: "Data",
          borderRadius: 5,
          borderWidth: 0,
          data: data.value,
          maxBarThickness: colWidthPx,
          barPercentage: 1,
          categoryPercentage: 1,
          backgroundColor: (context: ScriptableContext<any>) => {
            const { ctx, chartArea } = context.chart;

            if (!chartArea) return;

            return getGradient(ctx, chartArea);
          },
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      scales: {
        y: {
          min: props.min ?? undefined,
          max: props.max ?? undefined,
          ticks: {
            display: false,
            stepSize: tickSize,
          },
          grid: {
            drawTicks: true,
            color: "#E8EAED33",
          },
        },
        x: {
          grid: {
            drawTicks: false,
            display: false,
          },
          ticks: {
            color: "white",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        datalabels: {
          anchor: "end",
          align: (context) => {
            return (context.dataset.data.at(context.dataIndex)! as number) < 5 ? "end" : "start";
          },
          clamp: true,
          clip: true,
          display: true,
          font: {
            family: "sans-serif",
            weight: 500,
            style: "normal",
            size: 14,
          },
          color: "white",
        },
        tooltip: {
          enabled: false,
        },
      },
      layout: {
        padding: {
          top: 10,
        },
      },
      animations: {},
    },
  });

  legendChart = new Chart(legendCanvas.value!, {
    type: "bar",
    data: {
      datasets: [
        {
          label: "Data",
          data: data.value,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 12.5,
        },
      },
      scales: {
        y: {
          min: props.min ?? undefined,
          max: props.max ?? undefined,
          title: {
            display: false,
          },
          ticks: {
            stepSize: tickSize,
            color: "white",
          },
          grid: {
            drawTicks: false,
          },
          afterFit: (ctx) => {
            ctx.width = 35;
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
    },
  });
});

watch([data, labels], ([newData, newLabels]) => {
  if (!chart) return;

  if (
    newData.length * colWidthPx >
    (chartCanvas.value?.getBoundingClientRect().width ?? Number.MAX_SAFE_INTEGER)
  ) {
    chartCanvas.value!.parentElement!.style.width = `${newData.length * colWidthPx * 1.25}px`;
  }

  chart.data.labels = newLabels;

  chart.data.datasets.forEach((dataset) => {
    dataset.data.length = 0;
    dataset.data.push(...newData);
  });

  chart.update();
});

watch([width], () => {
  if (!chart) return;

  chart.update();
});
</script>

<template v-show="data.length !== 0">
  <figure class="flex w-full relative h-full">
    <div class="chart-legend absolute left-0 bottom-0 top-0 w-[35px]">
      <canvas ref="legendCanvas" width="100%"></canvas>
    </div>

    <div class="chart-data overflow-auto absolute left-[35px] bottom-0 top-0 right-0 no-scrollbar">
      <div class="chart-container h-full">
        <canvas ref="chartCanvas" height="100%"></canvas>
      </div>
    </div>
  </figure>
</template>

<style scoped></style>
