<script setup lang="ts">
import { getCountryISO2 } from "~/utils/countryIso";

const props = defineProps<{
  countryIso: string;
  width?: number;
}>();
</script>

<template>
  <img
    :src="`https://hatscripts.github.io/circle-flags/flags/${getCountryISO2(props.countryIso)}.svg`"
    :alt="`${props.countryIso}'s flag`"
  />
</template>

<style scoped></style>
