import type { GetNftsForOwnerOptions, OwnedNft, OwnedNftsResponse } from "alchemy-sdk";
import { Alchemy, Network } from "alchemy-sdk";
import { ethers } from "ethers";
import mitt from "mitt";
import { isProd, wmaticContractMainnet, wmaticContractMumbai } from "~/application/config";

export class AlchemyService {
  public alchemy: Alchemy;
  public emitter = mitt();

  public wmaticContract = isProd ? wmaticContractMainnet : wmaticContractMumbai;

  public isActive = false;

  constructor() {
    this.alchemy = new Alchemy({
      apiKey: import.meta.env.VITE_ALCHEMY_KEY,
      network: isProd ? Network.MATIC_MAINNET : Network.MATIC_MUMBAI,
    });
  }

  public async getNftsDetailForOwner(address: string, contracts?: string[]): Promise<OwnedNft[]> {
    const queryOptions: GetNftsForOwnerOptions = {
      contractAddresses: contracts,
    };
    const nfts: OwnedNftsResponse = await this.alchemy.nft.getNftsForOwner(address, queryOptions);
    // const uniqueList = new Set(nfts.ownedNfts.filter(nft => nft.contract.address === import.meta.env.VITE_APP_ERC721))
    // const uniqueList = nfts.ownedNfts.filter((n, i) => nfts.ownedNfts.indexOf(n) === i);
    return Promise.resolve(nfts.ownedNfts);
  }

  /**
   * Initialize the WebSocket connection to listen for WMATIC transfers related to the given address.
   * Emits a 'balanceUpdated' event when a transfer involving the address occurs.
   *
   * @param address - The address to monitor for WMATIC transfers.
   */
  public async initWmaticWs(address: string): Promise<void> {
    try {
      address = address.toLowerCase();

      const topic = ethers.utils.id("Transfer(address,address,uint256)");

      const filter = {
        address: this.wmaticContract,
        topics: [topic],
      };

      this.alchemy.ws.on(filter, async (log) => {
        if (log && log.topics) {
          const sender = ethers.utils.hexStripZeros(log.topics[1]).toLowerCase();
          const recipient = ethers.utils.hexStripZeros(log.topics[2]).toLowerCase();
          if ([sender, recipient].includes(address)) {
            try {
              const balance = await this.getWmaticBalance(address);
              if (balance) {
                this.emitter.emit("balanceUpdated", balance);
              }
            } catch (error) {
              console.error("Failed to update WMATIC balance:", error);
            }
          }
        }
      });
      this.isActive = true;
    } catch (error) {
      console.error("Failed to initialize WMATIC WebSocket:", error);
    }
  }

  /**
   * Retrieve the WMATIC balance of the given address.
   *
   * @param address - The address whose WMATIC balance should be fetched.
   * @returns The WMATIC balance as a BigNumber, or null if an error occurs or the balance is not found.
   */
  public async getWmaticBalance(address: string): Promise<ethers.BigNumber | null> {
    try {
      address = address.toLowerCase();
      const response = await this.alchemy.core.getTokenBalances(address, [this.wmaticContract]);
      if (response.tokenBalances.length && response.tokenBalances[0].tokenBalance) {
        return ethers.BigNumber.from(response.tokenBalances[0].tokenBalance);
      } else {
        console.warn("WMATIC balance not found for address:", address);
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch WMATIC balance for address:", address, error);
      return null;
    }
  }
}
