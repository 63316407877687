import { ref } from "vue";
import type { ICard } from "~/application/types/Card";
import type { BigNumber } from "ethers";

interface IPanelProps {
  item: ICard;
  amount: BigNumber;
}

type PanelProps = {
  BidPanel: IPanelProps;
};

const usePanel = () => {
  const panelComponent = ref<string>("");
  const panelProps = ref({});
  const panelTeleport = ref("body");

  function openPanel<K extends keyof PanelProps>(
    component: K,
    props: PanelProps[K],
    teleport?: string
  ) {
    panelProps.value = props;
    panelComponent.value = component;
    panelTeleport.value = teleport ?? panelTeleport.value;
  }
  const closeModal = () => {
    panelComponent.value = "";
    panelProps.value = {};
    panelTeleport.value = "body";
  };

  return {
    openPanel,
    closeModal,
    panelProps,
    panelComponent,
    panelTeleport,
  };
};

export default usePanel();
