<script setup lang="ts">
import { ChevronDownIcon, ChevronsDownUpIcon, ChevronUpIcon } from "lucide-vue-next";

const props = defineProps<{
  name: string;
  classes?: string | string[] | Record<string, boolean>;
  variationUnit?: string;
  variation?: number;
  positiveVariationClasses?: string;
  negativeVariationClasses?: string;
}>();

const posVariationClasses = props.positiveVariationClasses ?? "text-status-success";
const negVariationClasses = props.negativeVariationClasses ?? "text-status-error";
</script>

<template>
  <div>
    <div class="hidden md:flex flex-col items-stretch gap-[0.5]" :class="props.classes">
      <span class="uppercase font-light text-sm text-center tracking-widest">{{ props.name }}</span>
      <div
        class="background flex flex-row items-center justify-between rounded-md p-2 bg-black bg-opacity-50"
      >
        <slot name="icon">
          <div class="w-7"></div>
        </slot>

        <div class="font-display text-2xl">
          <span class="align-middle">
            <slot />
          </span>
          <span v-if="$slots.unit">&nbsp;<slot name="unit" /></span>
        </div>

        <div
          v-if="props.variation"
          :class="{
            'flex flex-row items-center justify-center': true,
            [posVariationClasses]: (props.variation ?? 0) > 0,
            [negVariationClasses]: (props.variation ?? 0) < 0,
          }"
        >
          <div v-if="props.variation != null" class="flex flex-row items-center">
            <span v-if="props.variation < 0">
              <ChevronDownIcon />
            </span>
            <span v-else-if="props.variation === 0">
              <ChevronsDownUpIcon />
            </span>
            <span v-else>
              <ChevronUpIcon />
            </span>
            <span>
              {{ props.variation }}
            </span>
          </div>
          <span v-if="props.variation != null && props.variationUnit != null">
            {{ props.variationUnit }}
          </span>
        </div>
        <div class="w-7"></div>
      </div>
    </div>

    <div class="flex md:hidden flex-row justify-between items-center" :class="props.classes">
      <span>
        {{ props.name }}
      </span>
      <div class="grow flex items-center justify-end gap-2">
        <div
          :class="{
            'flex flex-row items-center justify-center': true,
            [posVariationClasses]: (props.variation ?? 0) > 0,
            [negVariationClasses]: (props.variation ?? 0) < 0,
          }"
        >
          <div v-if="props.variation != null" class="flex flex-row items-center">
            <span v-if="props.variation < 0">
              <ChevronDownIcon />
            </span>
            <span v-else-if="props.variation === 0">
              <ChevronsDownUpIcon />
            </span>
            <span v-else>
              <ChevronUpIcon />
            </span>
            <span>
              {{ props.variation }}
            </span>
          </div>
          <span v-if="props.variation != null && props.variationUnit != null">
            {{ props.variationUnit }}
          </span>
        </div>
        <span class="align-middle font-semibold">
          <slot />
          <span v-if="$slots.unit">&nbsp;<slot name="unit" /></span>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
