import { Api } from "~/api/index";
import { IFlashSale } from "~/common/interfaces/IMarketplace";

export class FlashSaleApi extends Api {
  constructor() {
    super(`flash-sale`);
  }

  public async availableFlashSales(): Promise<Array<Omit<IFlashSale, "auction">>> {
    const { data } = await this.api.get<Array<Omit<IFlashSale, "auction">>>("");
    return data as Array<Omit<IFlashSale, "auction">>;
  }

  public async findOne(identifier: string): Promise<IFlashSale> {
    const { data } = await this.api.get<IFlashSale>(identifier);
    return data as IFlashSale;
  }
}
