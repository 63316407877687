<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import { onMounted, onUnmounted, ref } from "vue";
import ServiceIcon from "~/assets/icons/bw/service.svg";
import PowerIcon from "~/assets/icons/bw/power.svg";
import ReturnIcon from "~/assets/icons/bw/return.svg";
import MentalIcon from "~/assets/icons/bw/mental.svg";
import CardsFader from "~/components/modals/onboarding/common/CardsFader.vue";

const { t } = useI18n();

const onboardingStore = useOnboarding();
const { skillsDemoCards } = onboardingStore;

const activeCardIdx = ref(0);
const cardUpdateInterval = ref();

onMounted(() => {
  cardUpdateInterval.value = setInterval(() => {
    activeCardIdx.value = (activeCardIdx.value + 1) % skillsDemoCards.length;
  }, 2_000);
});
onUnmounted(() => clearInterval(cardUpdateInterval.value));
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col justify-end items-stretch md:justify-center z-0"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <transition>
        <PaginatedOnboardingMobileHeader with-profile-pic />
      </transition>

      <div
        class="grow w-full flex flex-col items-stretch md:flex-row md:justify-around gap-4 md:gap-10 overflow-y-auto"
      >
        <CardsFader :cards="skillsDemoCards" :timing="2_000" />

        <div class="flex flex-col items-stretch gap-2 animate-w">
          <div class="grow flex flex-col justify-center items-stretch gap-2">
            <p>
              {{ t("onboarding.step3.startTournaments") }}
            </p>

            <div class="grid grid-cols-2 grid-rows-2 xl:grid-rows-1 xl:grid-cols-4 gap-2 py-2">
              <div
                class="py-1 rounded-tl-md rounded-br-md bg-skill-service flex justify-center items-center"
              >
                <img :src="ServiceIcon" alt="Service" />
                <span class="capitalize">
                  {{ t("skills.service") }}
                </span>
              </div>
              <div
                class="py-1 rounded-tl-md rounded-br-md bg-skill-power flex justify-center items-center"
              >
                <img :src="PowerIcon" alt="Power" />
                <span class="capitalize">
                  {{ t("skills.power") }}
                </span>
              </div>
              <div
                class="py-1 rounded-tl-md rounded-br-md bg-skill-return flex justify-center items-center"
              >
                <img :src="ReturnIcon" alt="Return" />
                <span class="capitalize">
                  {{ t("skills.return") }}
                </span>
              </div>
              <div
                class="py-1 rounded-tl-md rounded-br-md bg-skill-mental flex justify-center items-center"
              >
                <img :src="MentalIcon" alt="Mental" />
                <span class="capitalize">
                  {{ t("skills.mental") }}
                </span>
              </div>
            </div>

            <p>
              {{ t("onboarding.step3.skillsScoreExplanation") }}
            </p>
          </div>

          <PaginatedOnboardingFooter
            class="p-2 hidden md:flex"
            continue-text-key="onboarding.step3.ok"
          />
        </div>
      </div>

      <div class="p-2 md:hidden">
        <PaginatedOnboardingFooter continue-text-key="onboarding.step3.ok" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active {
  @apply transition ease-out transform duration-1000;
}

.v-leave-active {
  @apply transition ease-in transform duration-200;
}

.v-enter-from,
.v-leave-to {
  @apply opacity-0;
}

.v-enter-to,
.v-leave-from {
  @apply opacity-100;
}
</style>
