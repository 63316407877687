import { onMounted, onUnmounted, ref } from "vue";

const overlayBaseClass = "highlighter-overlay";

export default function useHighlighter() {
  const highlightBox = ref<{ overlay: HTMLDivElement; update: VoidFunction } | null>(null);

  function createOverlay(
    element: HTMLElement,
    {
      classes,
      overlayColor,
      zIndex,
      parent,
    }: Partial<{ classes: string; overlayColor: string; zIndex: number; parent: HTMLElement }> = {
      classes: "",
      overlayColor: "rgb(30, 30, 30, 0.3)",
      zIndex: 80,
      parent: document.body,
    }
  ) {
    highlightBox.value?.overlay.remove();

    console.log(`Creating overlay`);

    const overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.boxShadow = `0 0 0 5000px ${overlayColor ?? "transparent"}`;
    overlay.style.zIndex = `${zIndex ?? 0}`;
    overlay.className = `${classes ?? ""} ${overlayBaseClass}`;

    (parent ?? document.body).appendChild(overlay);

    function update() {
      const rect = element.getBoundingClientRect();
      overlay.style.left = `${rect.left}px`;
      overlay.style.top = `${rect.top}px`;
      overlay.style.width = `${rect.width}px`;
      overlay.style.height = `${rect.height}px`;
    }

    update();

    highlightBox.value = { overlay, update };
    return highlightBox;
  }

  function removeOverlay() {
    highlightBox.value?.overlay.remove();

    window.removeEventListener("resize", handleResize);
    window.removeEventListener("scroll", handleScroll);

    highlightBox.value = null;
  }

  function removeAllOverlays() {
    console.log(`Removing all overlays`);
    document.querySelectorAll(`.${overlayBaseClass}`).forEach((overlay) => overlay.remove());
  }

  function handleResize() {
    highlightBox.value?.update();
  }

  function handleScroll() {
    highlightBox.value?.update();
  }

  onMounted(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, true);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", handleResize);
    window.removeEventListener("scroll", handleScroll);

    highlightBox.value = null;
  });

  return { createOverlay, removeOverlay, removeAllOverlays, highlightBox };
}
