import { useI18n } from "vue-i18n";
import { BigNumber, ethers } from "ethers";
import usePOLPrice from "~/stores/POLprice";

const useConvert = () => {
  const getMaticPriceInUsdWei = () => ethers.utils.parseUnits(`${usePOLPrice().rates.usd}`, 18);

  function formatUsd(value: number, symbol = true): string {
    const props = symbol
      ? {
          style: "currency",
          currency: "USD",
          currencyDisplay: "narrowSymbol",
        }
      : {};
    return new Intl.NumberFormat(useI18n().locale.value, {
      maximumFractionDigits: 2,
      notation: "compact",
      ...props,
    }).format(value);
  }

  type convertType<T extends boolean> = T extends true ? string : number;

  function mtcToUsd<T extends boolean = true>(
    value: number,
    format: T = true as T
  ): convertType<T> {
    const result = value * usePOLPrice().rates.usd;
    if (format) {
      return new Intl.NumberFormat(useI18n().locale.value, {
        maximumFractionDigits: 2,
        notation: "compact",
        style: "currency",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
      }).format(result) as convertType<T>;
    }
    return Number(result.toFixed(2)) as convertType<T>;
  }

  function usdToMtc<T extends boolean = true>(
    value: number,
    format: T = true as T,
    maximumFractionDigits = 2
  ): convertType<T> {
    const result = value / usePOLPrice().rates.usd;

    if (format) {
      return new Intl.NumberFormat(useI18n().locale.value, {
        maximumFractionDigits: maximumFractionDigits,
        notation: "compact",
      }).format(result) as convertType<T>;
    }
    return Number(result.toFixed(4)) as convertType<T>;
  }

  function weiToMatic(value: number | BigNumber) {
    return +ethers.utils.formatUnits(value, 18);
  }

  function weiToUsd(value: BigNumber) {
    const maticPriceInUsdWei = getMaticPriceInUsdWei();
    const usdWei = value.mul(maticPriceInUsdWei);
    return +ethers.utils.formatUnits(usdWei, 36);
  }

  function usdToWei(value: number | string) {
    const maticPriceInUsdWei = getMaticPriceInUsdWei();
    const usdWei = ethers.utils.parseUnits(value.toString(), 36);
    return usdWei.div(maticPriceInUsdWei);
  }

  function maticToWei(value: number | BigNumber | string) {
    return ethers.utils.parseUnits(value.toString(), "18");
  }

  return {
    formatUsd,
    mtcToUsd,
    usdToMtc,
    weiToMatic,
    maticToWei,
    weiToUsd,
    usdToWei,
  };
};

export default useConvert();
