import { NotifType } from "./storeApp";
import { defineStore } from "pinia";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { SeaportService } from "~/services/seaportService";
import { BigNumber } from "ethers";
import useConvert from "~/composables/useConvert";
import { displayError } from "~/utils/errors";
import { MercureService } from "~/services/mercureService";
import { useStoreApp } from "~/stores/storeApp";
import mitt from "mitt";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";
import { usePrimaryMarketplace } from "~/stores/storePrimaryMarketplace";
import { AuctionStatus } from "~/common/enums/auction";
import { useStoreFlashSale } from "~/stores/storeFlashSale";
import { useSecondaryMarketplace } from "~/stores/storeSecondaryMarketplace";
import { useStoreDeck } from "~/stores/storeDeck";
import { RemovableRef, useLocalStorage } from "@vueuse/core";
import {
  NftType,
  MercureEventType,
  AuctionUpdate,
  CantBidWithStripe,
  MarketType,
} from "fungi-types";
import { transactionApi } from "~/api/transaction";
import { pushToDataLayer } from "~/utils/gtm";

export type StoreMarketplaceEvent = {
  "update-auction": AuctionUpdate;
};

export const useStoreMarketplace = defineStore("marketplace-store", {
  state: () => {
    return {
      initialized: false,
      bidInProgress: false,
      mercureService: MercureService.getInstance(),
      emitter: mitt<StoreMarketplaceEvent>(),
      selectedItem: {} as IAuctionItem,
      type: useLocalStorage("MarketNftType", NftType.SKILL) as RemovableRef<NftType>,
    };
  },
  actions: {
    async init() {
      const storeFlashSale = useStoreFlashSale();

      if (this.initialized) return;

      this.mercureService.emitter.on(MercureEventType.AUCTION_UPDATE, (event) => {
        this.updateAuctionHandler(event);
      });

      this.mercureService.emitter.on(MercureEventType.CANT_BID_WITH_STRIPE, (event) => {
        this.cantBidWithStripeHandler(event);
      });

      this.initialized = true;

      await storeFlashSale.getAvailableFlashSales();
    },

    async cantBidWithStripeHandler(data: CantBidWithStripe) {
      const userId = useStoreUserV2().cuid;

      if (data.userCuid !== userId) return;

      useStoreApp().displayNotif(NotifType.OUTBID, data.auction, data.bidder.username);
    },

    async updateAuctionHandler(data: AuctionUpdate) {
      const storeFlashSale = useStoreFlashSale();
      const secondaryMarketplace = useSecondaryMarketplace();
      const storeDeck = useStoreDeck();

      let updated;
      if ([MarketType.PRIMARY, MarketType.PRIMARY_EQUIPMENT].includes(data.auction.marketType)) {
        updated = await usePrimaryMarketplace().updateAuction(data);
      } else if (
        [MarketType.FLASH_SALE, MarketType.FLASH_SALE_EQUIPMENT].includes(data.auction.marketType)
      ) {
        updated = storeFlashSale.updateAuction(data);
      } else if (
        [MarketType.SECONDARY, MarketType.SECONDARY_EQUIPMENT].includes(data.auction.marketType)
      ) {
        secondaryMarketplace.updateAuction(data);
        if (data.auction.status === AuctionStatus.UNLISTED) {
          secondaryMarketplace.removeCard(data.auction.cuid);
        }
        if (data.auction.card?.tokenId) {
          storeDeck.updateItemAuction(data.auction.card.tokenId, { status: data.auction.status });
        }
      }

      if (updated) {
        if (data.auction.status === AuctionStatus.CANCELLED) {
          this.emitter.emit("update-auction", data);
          return;
        }

        const userCuid = useStoreUserV2().cuid;

        const end = new Date(data.auction.endedAt);
        // check if auction is ended
        if (end.getTime() <= new Date().getTime()) {
          //display winner message
          if (data.auction.status === AuctionStatus.ENDED) {
            if (userCuid && userCuid === data.bidder.cuid) {
              useStoreApp().displayNotif(NotifType.WIN, updated.item, data.bidder.username);
            }
          }
        } else if (userCuid && updated.prevBidder?.cuid !== data.bidder.cuid) {
          if (updated.prevBidder?.cuid === userCuid && userCuid !== data.bidder.cuid) {
            useStoreApp().displayNotif(NotifType.OUTBID, updated.item, data.bidder.username);
          } else if (userCuid === data.bidder.cuid) {
            useStoreApp().displayNotif(NotifType.TOPBID, updated.item);
          }
        } else if (userCuid && userCuid === data.bidder.cuid) {
          // same bidder
          useStoreApp().displayNotif(NotifType.OVERBID, updated.item);
        }

        this.emitter.emit("update-auction", data);
      }
    },

    async bidOnCard(cuid: string, bidAmount: BigNumber): Promise<any> {
      const storeUser = useStoreUserV2();
      const mtcPrice = useConvert.weiToMatic(bidAmount);
      this.bidInProgress = true;
      try {
        if (storeUser.availableWMaticBalance < mtcPrice) {
          this.bidInProgress = false;
          return Promise.reject(Error("Not enough WMATIC"));
        }

        const offerer = storeUser.walletAddress;
        const res = await transactionApi.getOrderParam({
          cuid,
          bidAmount: bidAmount.toString(),
        });

        const seaportService = new SeaportService(storeUser.wallet.signer);
        const order = await seaportService.getOrderSignature(res, offerer);
        await transactionApi.submitOrder({ cuid, data: order });

        this.bidInProgress = false;
        pushToDataLayer({
          eventAction: "bid",
          bidAmount: mtcPrice,
          currency: "POL",
          oderId: cuid,
          userId: storeUser.cuid,
        });
        return Promise.resolve();
      } catch (error) {
        this.bidInProgress = false;
        displayError(error);
        return Promise.reject(error);
      }
    },
  },
});
