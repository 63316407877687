<script setup lang="ts">
import { computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import Stat from "~/components/stats/Stat.vue";
import DetailsSection from "~/components/card-details/DetailsSection.vue";
import PeriodSelector from "~/components/card-details/PeriodSelector.vue";
import { ScoreTimeframe } from "~/components/card-details/types/StatsTimeframes";
import { useCardDetails } from "~/components/card-details/use-card-details";
import { storeToRefs } from "pinia";
import { Skill } from "fungi-types";
import StatsSection from "~/components/card-details/StatsSection.vue";
import SkillIcon from "~/components/card-details/SkillIcon.vue";
import ScrollableBarChart from "~/components/charts/ScrollableBarChart.vue";

const { t } = useI18n();

const cardDetails = useCardDetails();
const { scores, skill } = storeToRefs(cardDetails);

const roundedScores = computed(() => {
  return scores.value.data.map((score) =>
    score.hasParticipated ? Math.round(score[skill.value!]!) : null
  );
});

const chartLabels = computed(() => {
  return scores.value.data.map((score) => score.gameweekNumber.toString());
});

const scoreStats = computed(() => {
  if (!roundedScores.value.length)
    return {
      current: 0,
      average: 0,
      presence: 0,
    };

  const current = Math.round(scores.value.stats.averages[skill.value!]!);
  const average = Math.round(scores.value.stats.averages[skill.value!]!);
  const presence = Math.round(100 * scores.value.stats.participationRate);

  return {
    current,
    average,
    presence,
  };
});

const scorePeriodOptions = computed(() => {
  return [
    { label: "L10", value: 10 },
    { label: "L20", value: 20 },
    { label: t("cardDetails.periods.all", "All"), value: undefined },
  ];
});

const skillsGradients = inject<Record<Skill, [string, string]>>("skills-gradients")!;
</script>

<template>
  <DetailsSection :name="t('cardDetails.stats.title', 'Statistics')">
    <template #time-picker>
      <PeriodSelector
        :options="scorePeriodOptions"
        :value="cardDetails.scoresDataPoints"
        :active-class="`bg-skill-${cardDetails.skill!}`"
        @change="(val) => (cardDetails.scoresDataPoints = val as ScoreTimeframe)"
      />
    </template>
    <template #default>
      <StatsSection>
        <Stat
          :name="t('cardDetails.stats.score', 'score')"
          :classes="{
            'bg-skill-${cardDetails.skill!} bg-opacity-100': true,
            '[&>div.background]:bg-skill-return': skill === Skill.RETURN,
            '[&>div.background]:bg-skill-service': skill === Skill.SERVICE,
            '[&>div.background]:bg-skill-mental': skill === Skill.MENTAL,
            '[&>div.background]:bg-skill-power': skill === Skill.POWER,
          }"
          class="grow"
          negative-variation-classes="text-status-error md:text-white"
          positive-variation-classes="text-status-success md:text-white"
        >
          <template #icon>
            <SkillIcon :skill="cardDetails.skill!" />
          </template>

          <template #default>
            {{ scoreStats.current }}
          </template>
        </Stat>

        <Stat :name="t('cardDetails.stats.presence', 'Presence')" class="grow">
          {{ scoreStats.presence }}%
        </Stat>
      </StatsSection>

      <h3 class="font-semibold text-sm">
        {{ t("cardDetails.stats.chartTitle", "Score changes over game weeks") }}
      </h3>

      <div class="h-[33vh] px-2 py-4 border border-slate-700 rounded-md">
        <ScrollableBarChart
          :data="roundedScores"
          :labels="chartLabels"
          :min="0"
          :max="100"
          :ticks="4"
          :gradient-color0="skillsGradients[skill!][0]"
          :gradient-color1="skillsGradients[skill!][1]"
        />
      </div>
    </template>
  </DetailsSection>
</template>

<style scoped></style>
