<script setup lang="ts"></script>

<template>
  <div
    class="flex flex-col md:flex-row md:justify-around items-stretch md:items-center gap-4 md:gap-2 bg-black bg-opacity-25 md:bg-opacity-0 p-4 md:p-0 rounded-lg border border-grey-30 border-opacity-20 md:border-0"
  >
    <slot />
  </div>
</template>

<style scoped></style>
