import jsonwebtoken from "jsonwebtoken";
enum StorageKeys {
  AdonisToken = "adonisToken",
  UserToken = "userToken",
  Web3authJWT = "jwtweb3auth",
  Web3authSessionEXP = "sessionExp",
}
const setItem = (key: string, value: string) => localStorage.setItem(key, value);
const removeItem = (key: string) => localStorage.removeItem(key);
const getItem = (key: string) => localStorage.getItem(key) || "";

export const adonisToken = {
  set: (value: string) => setItem(StorageKeys.AdonisToken, value),
  remove: () => removeItem(StorageKeys.AdonisToken),
  get: () => getItem(StorageKeys.AdonisToken),
};

export const userToken = {
  set: (value: string) => setItem(StorageKeys.UserToken, value),
  remove: () => removeItem(StorageKeys.UserToken),
  get: () => getItem(StorageKeys.UserToken),
};

export const UserTokenWeb3auth = {
  set: (value: string) => setItem(StorageKeys.Web3authJWT, value),
  remove: () => removeItem(StorageKeys.Web3authJWT),
  get: () => getItem(StorageKeys.Web3authJWT),
};

export const userSession = {
  removeAll: () => {
    adonisToken.remove();
    userToken.remove();
    UserTokenWeb3auth.remove();
    userSession.removeExp();
  },
  setExp: (value: string) => setItem(StorageKeys.Web3authSessionEXP, value),
  removeExp: () => removeItem(StorageKeys.Web3authSessionEXP),
  getExp: () => getItem(StorageKeys.Web3authSessionEXP),
  setFromWeb3JWT: (tokenId: string) => {
    UserTokenWeb3auth.set(tokenId);
    const token = jsonwebtoken.decode(tokenId);
    const { exp } = token as any;
    userSession.setExp(exp);
  },
  isAuth: () => {
    const exp = userSession.getExp();
    if (!exp) return false;
    const now = Date.now() / 1000;
    return Number(now) < Number(exp);
  },
};
