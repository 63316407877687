import i18n from "@/modules/i18n";
import { nextTick } from "vue";
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { EPreferredLanguage } from "fungi-types";

const Trans = {
  get defaultLocale() {
    return "en";
  },

  get supportedLocales(): EPreferredLanguage[] {
    return [EPreferredLanguage.EN, EPreferredLanguage.FR];
  },

  get currentLocale() {
    return i18n.global.locale;
  },

  set currentLocale(newLocale) {
    i18n.global.locale.value = newLocale;
  },

  async switchLanguage(newLocale: string) {
    await Trans.loadLocaleMessages(newLocale);
    Trans.currentLocale = newLocale;
    document.querySelector("html")?.setAttribute("lang", newLocale);
    localStorage.setItem("user-locale", newLocale);
  },

  async loadLocaleMessages(locale: string) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(`@/locales/${locale}.json`);
      i18n.global.setLocaleMessage(locale, messages.default);
    }

    return nextTick();
  },

  isLocaleSupported(locale: string) {
    return Trans.supportedLocales.includes(locale);
  },

  getUserLocale() {
    const locale = window.navigator.language || Trans.defaultLocale;

    return {
      locale: locale,
      localeNoRegion: locale.split("-")[0],
    };
  },

  getPersistedLocale() {
    const persistedLocale = localStorage.getItem("user-locale") || "";
    if (Trans.isLocaleSupported(persistedLocale)) return persistedLocale;
    else return null;
  },

  guessDefaultLocale() {
    const userPersistedLocale = Trans.getPersistedLocale();
    if (userPersistedLocale) {
      return userPersistedLocale;
    }

    const userPreferredLocale = Trans.getUserLocale();

    if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale;
    }

    if (Trans.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      return userPreferredLocale.localeNoRegion;
    }

    return Trans.defaultLocale;
  },

  async routeMiddleware(
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const paramLocale = to.params.locale as string;
    if (!Trans.isLocaleSupported(paramLocale)) {
      return next({
        name: to?.name || "",
        params: { ...to.params, locale: Trans.guessDefaultLocale() },
        query: to.query,
      });
    }

    await Trans.switchLanguage(paramLocale);

    return next();
  },

  i18nRoute(to: any) {
    return {
      ...to,
      params: {
        locale: Trans.currentLocale.value,
        ...to.params,
      },
    };
  },
};

export default Trans;
