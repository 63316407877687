<script setup lang="ts">
import { useStoreUserV2 } from "@/stores/storeUserV2";
import { computed } from "vue";
import router from "@/router";

import Discord from "/icon-discord.svg?url";
import Twitter from "/icon-twitter.svg?url";

import Trans from "~/modules/translations";
import { useStoreApp } from "~/stores/storeApp";
import Button from "./formsV2/Button.vue";

const storeUser = useStoreUserV2();
const storeApp = useStoreApp();

const routeName = computed(() => (router.currentRoute.value.name as string) || "");
async function logout() {
  await storeUser.logout();
  router.push(Trans.i18nRoute({ name: "Home" }));
}

const handleShowDepositModal = () => {
  storeApp.showDepositModal = true;
};

const displayLogout = computed<boolean>(
  () => ["MyAccount"].includes(routeName.value) && !storeApp.showReferralModal
);
const displaySignIn = computed<boolean>(() => !["MyAccount", "Login"].includes(routeName.value));

const goToPage = (page: "SignUp" | "Login") => {
  router.push(Trans.i18nRoute({ name: page }));
};
</script>

<template>
  <!-- Account status -->
  <div
    class="flex bg-primary-110 bg-opacity-50 rounded-[68px]"
    v-if="storeUser.isWalletConnected && !displayLogout"
  >
    <div class="px-f3 flex items-center">
      <div
        @click="handleShowDepositModal"
        class="w-f4 h-f4 flex items-center justify-center rounded-full bg-white hover:bg-primary bg-opacity-10 cursor-pointer"
      >
        <icon-fg-add />
      </div>
      <p class="font-medium pt-[1px] pl-f3 pr-f1">
        {{ storeUser.availableWMaticBalance.toFixed(2) }}
      </p>
      <icon-fgc-w-matic class="w-f3 h-f3" />
    </div>
    <router-link :to="Trans.i18nRoute({ name: 'MyAccount' })" class="flex items-center gap-f2">
      <p class="overflow-hidden font-display uppercase whitespace-nowrap text-ellipsis max-w-28">
        {{ storeUser.username }}
      </p>
      <img
        src="/images/avatar.png"
        alt="avatar"
        width="110"
        height="110"
        class="w-f5 h-f5 rounded-full"
      />
    </router-link>
  </div>
  <div v-else-if="displayLogout" class="flex">
    <Button type="primary-outline" size="sm" @click="logout">
      {{ $t("nav.logout") }}
    </Button>
  </div>
  <div v-else-if="displaySignIn" class="flex gap-4 items-center">
    <div class="flex flex-row gap-2">
      <a
        href="https://x.com/Fungiball_off"
        target="_blank"
        class="p-2 border flex px-2.5 items-center border-neutral-200 rounded-full hover:cursor-pointer hover:border-white transition-colors"
      >
        <img :src="Twitter" alt="Twitter" class="h-4" />
      </a>
      <a
        href="https://discord.gg/YJJw7RP33K"
        target="_blank"
        class="p-2 border flex items-center border-neutral-200 rounded-full hover:cursor-pointer hover:border-white transition-colors"
      >
        <img :src="Discord" alt="Discord" class="h-5" />
      </a>
    </div>
    <Button @click="goToPage('SignUp')" size="sm" class="hidden sm:block" type="primary-outline">
      {{ $t("nav.signup") }}</Button
    >
    <Button @click="goToPage('Login')" size="sm" class="hidden sm:block" type="primary-white">
      {{ $t("nav.login") }}</Button
    >
  </div>
</template>

<style scoped></style>
