export enum CardRarity {
  PLATINUM = "PLATINUM",
  GOLD = "GOLD",
  SILVER = "SILVER",
}
export enum CardSkills {
  MENTAL = "MENTAL",
  POWER = "POWER",
  SERVICE = "SERVICE",
  RETURN = "RETURN",
}
