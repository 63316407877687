import { Skill } from "fungi-types";

export type PlayerScoreRecord =
  | ({
      hasParticipated: true;
      gameweekNumber: number;
    } & {
      [key in Skill]: number;
    })
  | ({ hasParticipated: false; gameweekNumber: number } & { [key in Skill]: null });

export type PlayerScoreRecordDto = Omit<PlayerScoreRecord, "hasParticipated" | "gameweekNumber"> & {
  has_participated: boolean;
  gameweek_number: number;
};

export type PlayerScores = {
  data: PlayerScoreRecord[];
  stats: {
    dataPoints: number;
    participationRate: number;
    averages: {
      [key in Skill]: number;
    };
  };
};

export type PlayerScoresDto = {
  data: PlayerScoreRecordDto[];
  stats: {
    data_points: number;
    participation_rate: number;
    averages: {
      [key in Skill]: number;
    };
  };
};

export function mapPlayerScoresDtoToPlayerScores(dto: PlayerScoresDto): PlayerScores {
  return {
    data: dto.data.map((record) => {
      return record.has_participated
        ? {
            hasParticipated: true,
            gameweekNumber: record.gameweek_number,
            mental: record[Skill.MENTAL]!,
            power: record[Skill.POWER]!,
            return: record[Skill.RETURN]!,
            service: record[Skill.SERVICE]!,
          }
        : {
            hasParticipated: false,
            gameweekNumber: record.gameweek_number,
            mental: null,
            power: null,
            return: null,
            service: null,
          };
    }),
    stats: {
      dataPoints: dto.stats.data_points,
      participationRate: dto.stats.participation_rate,
      averages: dto.stats.averages,
    },
  };
}
