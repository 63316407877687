<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div
    class="xl:max-w-[50%] md:max-w-[50%] flex flex-col justify-end items-stretch md:justify-center z-0 pointer-events-none"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4 pointer-events-auto"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <div class="flex flex-col gap-4">
        <p>
          {{ t("onboarding.step12.congrats") }}
        </p>
        <p>
          <i18n-t keypath="onboarding.step12.rtfm">
            <template #docsLink>
              <a
                :href="t('onboarding.step12.docsUri')"
                target="_blank"
                class="underline underline-offset-2 hover:underline-offset-4"
              >
                {{ t("onboarding.step12.here") }}
              </a>
            </template>
          </i18n-t>
        </p>
        <p class="hidden md:block">
          {{ t("onboarding.step12.availableOnTheTopRightCornerOfTheScreen") }}
        </p>
      </div>

      <div class="p-2">
        <PaginatedOnboardingFooter continue-text-key="onboarding.step12.ok" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
