<script setup lang="ts">
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import { X } from "lucide-vue-next";
import i18n from "~/modules/i18n";
import { storeToRefs } from "pinia";

const props = withDefaults(
  defineProps<{
    withProfilePic?: boolean;
    forceDisplay?: boolean;
  }>(),
  {
    withProfilePic: false,
    forceDisplay: false,
  }
);

const { t } = i18n.global;

const onboardingStore = useOnboarding();
const { step } = storeToRefs(onboardingStore);
</script>

<template>
  <div :class="`flex items-center justify-between gap-3 ${forceDisplay ? '' : 'lg:hidden'}`">
    <img
      v-show="withProfilePic"
      class="avatar h-10 w-10"
      src="/images/coach/coach-pp.png"
      alt="Coach profile pic"
    />

    <div class="page-data grow font-sans font-normal">
      {{ t("onboarding.pages.outOf", [step, onboardingStore.totalSteps]) }}
    </div>

    <button
      @click="onboardingStore.exitOnboarding"
      class="self-start p-2 hover:bg-slate-800 bg-transparent border-slate-600 block lg:hidden"
    >
      <X class="h-5 w-5" />
    </button>
  </div>
</template>
