<script setup lang="ts">
import { scaledNftImg, ThumbSize } from "~/composables/useThumbs";
import { computed, ref } from "vue";
import { CardType, NftEquipment, NftSkill } from "fungi-types";

const props = withDefaults(
  defineProps<{
    nft: NftSkill | NftEquipment;
    background?: boolean;
  }>(),
  {
    background: true,
  }
);

const emits = defineEmits<{
  (event: "skill-click", nft: NftSkill): void;
  (event: "equipment-click", nft: NftEquipment): void;
}>();

const nftImage = ref();

function handleCardClick() {
  if (props.nft.card_type === CardType.NftEquipment) {
    emits("equipment-click", props.nft as NftEquipment);
  } else {
    emits("skill-click", props.nft as NftSkill);
  }
}

const IMG = computed(() => {
  // const num = Math.floor(Math.random() * 8) + 1;
  // if (num > 4) return scaledNftImg(props.nft.image, ThumbSize.L);
  // return `http://localhost:5173/images/tmp_cards/card_${num}.png`;
  return scaledNftImg(props.nft.image, ThumbSize.XL);
});
</script>

<template>
  <div
    :class="{ 'card-container': background }"
    class="flex flex-col gap-3 rounded-lg"
    @click.stop="handleCardClick"
  >
    <div class="flex flex-1 flex-col p-4 gap-3 overflow-hidden relative">
      <div class="flex relative justify-center">
        <div class="relative w-fit">
          <image-skeleton
            ref="nftImage"
            :src="IMG"
            :alt="nft?.slug || nft.title"
            :aspect-ratio="0.63"
          />
        </div>
      </div>
      <div class="footer-data">
        <slot name="footer-data" />
      </div>
      <slot name="cta" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-container {
  @apply transition-all duration-200 ease-in-out;
  // position: relative;
  // border-radius: 10px;
  // background: linear-gradient(205deg, rgba(249, 250, 251, 0.1) 0%, rgba(249, 250, 251, 0) 50.08%),
  //   linear-gradient(25deg, rgba(0, 12, 20, 0.1) 0%, rgba(0, 12, 20, 0) 49.92%),
  //   rgba(0, 27, 46, 0.15);
  // box-shadow:
  //   0 0 26px 0 rgba(0, 12, 20, 0.1),
  //   inset 0 0 26px 0 rgba(0, 12, 20, 0.1);
  // backdrop-filter: blur(3px);
}

.card-container::before {
  // content: "";
  // position: absolute;
  // inset: 0;
  // padding: 1px;
  // background: linear-gradient(0deg, #939ba900, #939ba999);
  // border-radius: 10px;
  // mask:
  //   linear-gradient(#fff 0 0) content-box,
  //   linear-gradient(#fff 0 0);
  // mask-composite: exclude;
  // -webkit-mask:
  //   linear-gradient(#fff 0 0) content-box,
  //   linear-gradient(#fff 0 0);
  // -webkit-mask-composite: xor;
  // z-index: -1;
}

.card-container:hover {
  // background: linear-gradient(205deg, rgba(249, 250, 251, 0.1) 0%, rgba(249, 250, 251, 0.05) 50.08%),
  //   linear-gradient(25deg, rgba(0, 12, 20, 0.2) 0%, rgba(0, 12, 20, 0) 49.92%),
  //   rgba(0, 27, 46, 0.35);
}
</style>
