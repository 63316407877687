<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";

const { t } = useI18n();

const { exitOnboarding } = useOnboarding();
const userStore = useStoreUserV2();
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col items-stretch sm:max-w-xl w-full z-0 justify-end md:justify-center"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <PaginatedOnboardingMobileHeader />

      <div class="flex flex-col gap-4">
        <div
          class="bg-cover bg-center h-40 lg:h-52 top-2 left-2 right-2 rounded-xl bg-[url(/images/coach/coach-frame.png)] lg:bg-[url(/images/courts/hard_outdoor_2.jpg)]"
        ></div>

        <h2 class="font-display text-3xl">{{ t("onboarding.step1.welcomeToFungiball") }}</h2>

        <p>
          <i18n-t keypath="onboarding.step1.greetings">
            <template #username>
              <span class="text-secondary-500">{{ userStore.username }}</span>
            </template>
          </i18n-t>
        </p>
      </div>

      <div class="p-2">
        <PaginatedOnboardingFooter
          continue-text-key="onboarding.step1.start"
          mobile-col-footer
          without-skip
        >
          <template #back-button>
            <button @click.prevent.stop="exitOnboarding">
              {{ t("onboarding.step1.noThanks") }}
            </button>
          </template>
        </PaginatedOnboardingFooter>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
