import { makeApi } from "~/api/index";
import OnboardingEventType from "~/application/enums/OnboardingEventType";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";

const baseUrl = import.meta.env.VITE_API_URL;
const api = makeApi(`${baseUrl}/onboarding`);

export const onboardingApi = {
  async getLastEvent(): Promise<OnboardingEventType | null> {
    try {
      const response = await api.get<{ event: OnboardingEventType | null }>("/last");

      if (response.status !== 200 || response.data.event === null) return null;

      const event = response.data.event;

      // If the last onboarding event was a step progress, setup onboarding to that step
      if (event.startsWith("step_")) {
        useOnboarding().setStep(+event.split("step_")[1]!);
      }

      return event;
    } catch (err) {
      console.error(`Error while fetching user last onboarding event`, err);
      // If something failed, we do not want the onboarding to be displayed to the user
      return OnboardingEventType.IGNORED;
    }
  },

  async recordEvent(event: OnboardingEventType) {
    await api.post("/", {
      event,
    });
  },
};
