export const scaledNftImg = (url: string, size: ThumbSize = ThumbSize.M) => {
  const urlParts = url.split("/");
  const filenameWithExtension = urlParts.pop();

  const baseUrl = urlParts.join("/");
  const filename = filenameWithExtension?.split(".")[0] || "default";
  const extension = filenameWithExtension?.split(".")[1] || "png";

  // const [, baseUrl, imgFile, imgExtension] = /^(.+?)\/(\d+?)\.(\w+?)$/.exec(url) ?? [];
  return `${baseUrl}/thumbs/${filename}_${size.toString()}x${size.toString()}.${extension}`;
};

/**
 * Thumb size is arbitrary defined through a script in gcp infrastructure
 * Do not modify theses values
 */
export enum ThumbSize {
  S = 150,
  M = 300,
  L = 400,
  XL = 600,
}
