enum OnboardingEventType {
  STEP_1 = "step_1",
  STEP_2 = "step_2",
  STEP_3 = "step_3",
  STEP_4 = "step_4",
  STEP_5 = "step_5",
  STEP_6 = "step_6",
  STEP_7 = "step_7",
  STEP_8 = "step_8",
  STEP_9 = "step_9",
  STEP_10 = "step_10",
  STEP_11 = "step_11",
  STEP_12 = "step_12",
  STEP_13 = "step_13",
  STEP_14 = "step_14",
  STEP_15 = "step_15",
  STEP_16 = "step_16",
  STEP_17 = "step_17",
  IGNORED = "ignored",
  RESTARTED = "restarted",
}

export default OnboardingEventType;
