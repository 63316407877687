"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Brand = exports.Tiers = exports.RewardType = exports.RegistrationStatus = exports.Surface = exports.MatchType = exports.PriceCurrency = exports.GameweekGender = exports.Scarcity = exports.GameweekStatus = void 0;
var GameweekStatus;
(function (GameweekStatus) {
    GameweekStatus["Closed"] = "closed";
    GameweekStatus["Canceled"] = "canceled";
    GameweekStatus["Ongoing"] = "ongoing";
    GameweekStatus["Upcoming"] = "upcoming";
})(GameweekStatus || (exports.GameweekStatus = GameweekStatus = {}));
var Scarcity;
(function (Scarcity) {
    Scarcity["SILVER"] = "silver";
    Scarcity["GOLD"] = "gold";
    Scarcity["PLATINUM"] = "platinum";
})(Scarcity || (exports.Scarcity = Scarcity = {}));
var GameweekGender;
(function (GameweekGender) {
    GameweekGender["MALE"] = "Male";
    GameweekGender["FEMALE"] = "Female";
    GameweekGender["MIXED"] = "mixed";
})(GameweekGender || (exports.GameweekGender = GameweekGender = {}));
var PriceCurrency;
(function (PriceCurrency) {
    PriceCurrency["$"] = "$";
})(PriceCurrency || (exports.PriceCurrency = PriceCurrency = {}));
var MatchType;
(function (MatchType) {
    MatchType["Singles"] = "singles";
    MatchType["Doubles"] = "doubles";
    MatchType["Mixed"] = "mixed";
})(MatchType || (exports.MatchType = MatchType = {}));
var Surface;
(function (Surface) {
    Surface["HardIndoor"] = "hard_outdoor";
    Surface["HardOutdoor"] = "hard_indoor";
    Surface["ClayIndoor"] = "clay_outdoor";
    Surface["ClayOutdoor"] = "clay_indoor";
    Surface["GrassIndoor"] = "grass_outdoor";
    Surface["GrassOutdoor"] = "grass_indoor";
})(Surface || (exports.Surface = Surface = {}));
var RegistrationStatus;
(function (RegistrationStatus) {
    RegistrationStatus["Draft"] = "draft";
    RegistrationStatus["Submitted"] = "submitted";
})(RegistrationStatus || (exports.RegistrationStatus = RegistrationStatus = {}));
var RewardType;
(function (RewardType) {
    RewardType["SKILL"] = "skill";
    RewardType["EQUIPMENT"] = "equipment";
    RewardType["USD"] = "usd";
})(RewardType || (exports.RewardType = RewardType = {}));
var Tiers;
(function (Tiers) {
    Tiers["S"] = "S";
    Tiers["A"] = "A";
    Tiers["B"] = "B";
    Tiers["C"] = "C";
    Tiers["D"] = "D";
})(Tiers || (exports.Tiers = Tiers = {}));
var Brand;
(function (Brand) {
    Brand["FUNGIBALL"] = "Fungiball";
    Brand["LECOQSPORTIF"] = "Le coq sportif";
})(Brand || (exports.Brand = Brand = {}));
