<script setup lang="ts">
import SvgImage from "/fugiball-logo-txt.svg?url";
import Twitter from "/icon-twitter.svg?url";
import Instagram from "/icon-instagram.svg?url";
import Discord from "/icon-discord.svg?url";
import Youtube from "/icon-youtube.svg?url";
import Tiktok from "/icon-tiktok.svg?url";
import Linkedin from "/icon-linkedin.svg?url";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { Globe } from "lucide-vue-next";

import InputSelect from "~/components/tournaments/leaderboard/InputSelect.vue";
import Trans from "~/modules/translations";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { ref } from "vue";

const router = useRouter();
const { locale, t } = useI18n();
const userStore = useStoreUserV2();

const footerLinks = [
  { label: "legal.terms-and-conditions", name: "terms-and-conditions" },
  { label: "legal.privacy-policy", name: "privacy-policy" },
  { label: "legal.cookie-policy", name: "cookie-policy" },
  { label: "legal.game-rules", name: "game-rules" },
  { label: "nav.blog", name: "Blog" },
];

const languages = [
  { id: "en", label: "English", value: "en" },
  { id: "fr", label: "Français", value: "fr" },
];

const widgestDeskiSOpen = ref<boolean>(false);

const handleShowSupport = async () => {
  const w = window as any;
  console.log("widgestDeskiSOpen.value", widgestDeskiSOpen.value);
  w.FreshworksWidget(widgestDeskiSOpen.value ? "hide" : "show", "launcher");
  w.FreshworksWidget(widgestDeskiSOpen.value ? "close" : "open");

  widgestDeskiSOpen.value = !widgestDeskiSOpen.value;
  w.FreshworksWidget("hide", "ticketForm", ["name", "title"]);
  const userCuid = userStore.cuid;
  if (userCuid) {
    w.FreshworksWidget("identify", "ticketForm", {
      email: userStore.email,
      custom_fields: {
        cf_username: userStore.username,
      },
    });
  }
};

const handleLanguageChange = async (locale: { id: string; label: string; value: string }) => {
  const newLocale = locale.value;
  await Trans.switchLanguage(newLocale);

  try {
    await router.replace({ params: { locale: newLocale } });
  } catch (e) {
    router.push(Trans.i18nRoute({ name: "Home" }));
  }
};

const socialNetworks = [
  {
    name: "Twitter",
    url: "https://x.com/Fungiball_off",
    icon: Twitter,
  },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/company/fungiball",
    icon: Linkedin,
  },
  {
    name: "Youtube",
    url: "https://www.youtube.com/@Fungiball",
    icon: Youtube,
  },
  {
    name: "Tiktok",
    url: "https://www.tiktok.com/@fungiball_",
    icon: Tiktok,
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/fungiball.io",
    icon: Instagram,
  },
  {
    name: "Discord",
    url: "https://discord.gg/YJJw7RP33K",
    icon: Discord,
  },
];
</script>

<template>
  <div class="flex mt-10 text-gray-500 bg-[#0d1117]">
    <div class="w-full py-6 p-6 lg:py-6 lg:p-0 text-sm pb-20">
      <div class="px-6 flex flex-col gap-2">
        <div
          class="flex md:flex-row flex-col md:items-center items-start md:gap-4 gap-2 mb-4 md:mb-2"
        >
          <img :src="SvgImage" alt="Fungiball" class="h-4" />
          <div class="flex md:flex-row -ml-2 md:-ml-0">
            <a
              v-for="items in socialNetworks"
              :href="items.url"
              target="_blank"
              class="p-2 border-[1px] hover:border-white rounded-full hover:cursor-pointer border-white/0 transition-colors bg-white/0 hover:bg-white/10"
            >
              <img :src="items.icon" alt="Twitter" class="h-4" />
            </a>
          </div>
        </div>
        <div class="flex flex-col md:flex-row md:gap-6 gap-3">
          <router-link
            :to="Trans.i18nRoute({ name: link.name })"
            v-for="link in footerLinks"
            :key="link.name"
            class="hover:text-white transition-colors text-white/80 hover:underline"
            >{{ t(link.label) }}</router-link
          >
          <a
            class="hover:text-white transition-colors text-white/80 hover:underline hover:cursor-pointer"
            @click="handleShowSupport"
          >
            Contact
          </a>
        </div>
        <div class="flex flex-row justify-between mt-4 items-center">
          <div class="w-fit">
            <InputSelect
              size="sm"
              class="text-xs"
              :selectedId="locale"
              @onChange="handleLanguageChange"
              :options="languages"
              :prefix="Globe"
              position="top"
            />
          </div>
          <div>© FUNGIBALL 2024</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
